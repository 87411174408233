@import "../../App.scss";

.run-code-view {
    .run-header {
        background: $dark-background;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Poppins;

        h3 {
            color: #fff;
            font-size: 14px;
        }
        .submit-btn {
            background-color: $button-color;
            color: #fff;
            width: 160px;
            height: 35px;
            border: none;
            font-size: 14px;
            text-align: center;
        }
    }
    .complie-window {
        padding: 15px 10px;
        margin-left: 2px;
        margin-top: 2px;
        display: flex;
        border: 1px solid #d7dadb;
        h5 {
            //styleName: Body Text;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: $body-text;
            margin-bottom: 5px;
        }
        h2 {
            //styleName: Body text bold;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            color: $dark-background;
        }
        label {
            //styleName: Caption 10;
            font-family: Poppins;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            color: #000000;
        }
        .run-btn {
            background-color: $button-color;
            color: #fff;
            height: 32px;
            width: 128px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            border: none;
        }
    }
}
.coding-container {
    .lang-dd {
        padding: 8px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .react-switch-bg {
            width: 28px;
            height: 14px;
            background: #bcc1c2;
        }
        .react-switch-handle {
            width: 12px;
            height: 12px;
        }
        label {
            //styleName: Caption 10;
            font-family: Poppins;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
        }
    }
    h6 {
        //styleName: Body Text;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: $body-text;
    }
    .output-btn {
        border: none;
        background-color: $button-color;
        color: #fff;
        padding: 8px 20px;
        margin-top: 20px;
        border-radius: 0px;
        font-size: 14px;
        text-align: center;
    }

    .css-13cymwt-control {
        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }

    .css-t3ipsp-control {
        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }
}
