@import "../../../App.scss";

.gappeo-test-results {
    // margin-top: -32px;
    margin-bottom: 20px;
    .stepper-header {
        height: 48px;
        width: unset;
        background-color: #fff;
        box-shadow: 0px 1px 1px 0px #0000001a;
        margin-left: -130px;
        margin-right: -130px;
        display: flex;
        align-items: center;
        padding-left: 50px;
        padding-right: 50px;
        .next-btn {
            background-color: $button-color;
            color: #fff;
            width: 90px;
            height: 32px;
            border: none;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
        }
        .back {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
            width: 10%;
            img {
                vertical-align: middle;
            }
        }
        .stepper-container {
            width: 75%;
            display: flex;
            align-items: center;
            .stepper-step {
                position: relative;
                flex: 1;
                text-align: center;
                margin-top: 5px;

                .stepper-line {
                    flex: 1;
                    height: 0px;
                    background: #ccc; /* Change the color as needed */
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 0;
                        border-top: 2px dashed #ccc; /* Change the color as needed */
                        z-index: 0;
                    }
                }
                .stepper-circle {
                    width: 10px;
                    height: 10px;
                    background: #fff; /* Change the background color as needed */
                    border: 2px solid #ccc; /* Change the border color as needed */
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 0;
                    margin-top: -4px;
                }
                .step-label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                    position: absolute;
                    top: -22px;
                    left: 50%;
                    height: 42px;
                    transform: translateX(-50%);
                    background: #fff;
                    padding: 0 8px;
                    white-space: nowrap;
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: $caption;
                }
            }
            .active {
                .stepper-circle {
                    background: $success; /* Change the background color for active step */
                    border-color: $success; /* Change the border color for active step */
                    color: #fff; /* Change the text color for active step */
                }
                .stepper-line {
                    &::before {
                        border-top: 2px dashed $success;
                    }
                }
                .step-label {
                    background-color: $success;
                    color: #fff;
                    height: 48px;
                    margin-top: -2px;
                }
                .img-circle {
                    background: #fff !important;
                }
            }
        }
    }
    .gappeo-test-lists {
        margin-left: -75px;
        margin-right: -60px;
        width: unset;
        margin-top: 10px;
        // display: flex;
        .next-btn {
            margin-top: 15px;
            button {
                width: 160px;
                height: 40px;
                background-color: $button-color;
                color: #fff;
                border: none;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
            }
        }
        .gappeo-test-data {
            width: 100%;
            .skill-search {
                position: relative;
                border-radius: 4px;
                box-shadow: 0px 1px 1px 0px #0000001a;
                background-color: #fff;
                padding: 20px 10px 18px 15px;
                // height: 80px;
                display: flex;
                flex-direction: column;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
                flex-wrap: wrap;
                .type-btn {
                    min-width: 120px;
                    height: 40px;
                    border: 1px solid #d7dadb;
                    border-radius: 4px;
                    font-size: 13px;
                    font-weight: 400;
                    color: $heading;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
                .active-type {
                    background-color: #b1e0c2;
                    border: 1px solid #40c572;
                    font-weight: 600;
                }
                .has-search {
                    position: relative;
                    width: 76%;

                    .form-control-feedback {
                        position: absolute;
                        z-index: 0;
                        display: block;
                        height: 2.375rem;
                        line-height: 2.375rem;
                        text-align: center;
                        pointer-events: none;
                        color: #aaa;
                        top: 0px;
                        width: 16px;
                        left: 8px;
                        img {
                            width: 20px;
                        }
                    }

                    .form-control {
                        padding-left: 2.3rem;
                        height: 40px;
                    }
                    input {
                        border-radius: 0px;
                        border: 1px solid #bcc1c2;
                        color: $body-text;
                    }
                }
                select {
                    border-left: 1px solid #d7dadb !important;
                    border-radius: 0px;
                    border: 1px solid #bcc1c2;
                    background-image: url("../../../assets/select-indicator.svg");
                    background-repeat: no-repeat;
                    background-size: 15px;
                    background-position: 93%;
                    height: 40px;
                    font-size: 0.9rem;
                    box-shadow: none !important;
                    cursor: pointer;
                    color: $body-text;
                    &:focus {
                        box-shadow: none;
                    }
                }
                .search-line {
                    //styleName: Caption;
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $button-color;
                }
                h4 {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #000000;
                }
            }
            .select-list {
                width: 76%;
                background-color: #fff;
                margin-top: 5px;
                box-shadow: 0px 1px 1px 0px #0000001a;
                border-radius: 4px;
                padding: 15px 18px;
                p {
                    //styleName: Caption;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $success;
                }
                .non-core-container {
                    display: flex;
                    flex-direction: row;
                    margin: 8px 0px;
                }
                .non-core-tags {
                    text-transform: capitalize;
                    // width: 65%;
                    border-radius: 2px;
                    padding: 4px 8px;
                    background-color: #f2f3f3;
                    //styleName: Caption 10;
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: $heading;
                    width: fit-content;
                    margin-right: 8px;
                    cursor: pointer;
                    border: 2px solid #d8dadb;
                }
                .non-core-tags.active {
                    background-color: black;
                    color: white;
                }

                .list-skill {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 5px;
                    max-height: 400px;
                    min-height: 175px;
                    overflow: auto;
                    & ::-webkit-scrollbar-track {
                        border-radius: 10px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: #ffffff;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        max-height: 10px;
                        background-color: #e9e8e8;
                    }
                    input[type="checkbox"] {
                        width: 18px;
                        height: 18px;
                    }
                    .list-skill-item {
                        border: 1px solid #d7dadb;
                        height: 80px;
                        border-radius: 4px;
                        width: 31%;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        margin-right: 12px;
                        margin-top: 5px;
                        cursor: pointer;
                        & :nth-child(3n) {
                            margin-right: 0px;
                        }
                        .skill-type {
                            text-transform: capitalize;
                            // width: 65%;
                            border-radius: 2px;
                            padding: 4px 8px;
                            background-color: #f2f3f3;
                            //styleName: Caption 10;
                            font-family: Poppins;
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 14px;
                            letter-spacing: 0px;
                            text-align: center;
                            color: $heading;
                        }
                        h4 {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 20px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: #3e67b7;
                        }
                        p {
                            //styleName: Caption;
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $body-text;
                        }
                    }
                }
            }
            .empty-list {
                background-color: #fff;
                margin-top: 5px;
                box-shadow: 0px 1px 1px 0px #0000001a;
                border-radius: 4px;
                padding: 25px 18px;
                text-align: center;
                padding-bottom: 50px;
                p {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: $success;
                }
            }
        }
        .gappeo-add-question {
            width: 75%;

            p {
                //styleName: Body Text;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
                color: $body-text;
                span {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $button-color;
                }
            }
            .add-question {
                width: 100%;
                margin-top: 20px;
                h3 {
                    //styleName: Section Heading;
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $heading;
                }
                p {
                    //styleName: Body Text;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $heading;
                }
                .outline-btn {
                    margin-top: 10px;
                    background-color: #fff;
                    border: 1px solid $button-color;
                    border-radius: 0px;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: $button-color;
                    min-width: 160px;
                    height: 40px;
                }
                .question-list {
                    margin-top: 15px;
                    width: 85%;
                    height: 305px;
                    overflow-y: auto;
                    & ::-webkit-scrollbar-track {
                        border-radius: 10px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: #ffffff;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        max-height: 10px;
                        background-color: #e9e8e8;
                    }
                    .question {
                        width: 98%;
                        // height: 89px;
                        border: 1px solid #d7dadb;
                        border-radius: 4px;
                        padding: 15px;
                        margin-top: 10px;
                        h4 {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $body-text;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        span {
                            //styleName: Caption;
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $body-text;
                        }
                    }
                }
            }
        }
        .gappeo-preview {
            width: 100%;
            margin-top: 5px;
            .preview-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px;
                box-shadow: 0px 1px 1px 0px #0000001a;
                background-color: #212425;
                p {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #fff;
                }
                h3 {
                    //styleName: Section Heading;
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #fff;
                }
                h2 {
                    font-family: Poppins;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 40px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $success;
                    display: flex;
                    align-items: center;
                    span {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 16px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $success;
                    }
                }
                .detail-btn {
                    color: #fff;
                    border: 1px solid #fff;
                    width: 152px;
                    height: 40px;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: center;
                    background-color: transparent;
                }
                h6 {
                    margin-top: 10px;
                    //styleName: Action Text;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: $caption;
                }
            }
            .invite-details {
                padding: 25px 35px;
                margin-top: 12px;
                background-color: #fff;
                border-radius: 4px;
                box-shadow: 0px 1px 1px 0px #0000001a;
                h1 {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $heading;
                }
                p {
                    //styleName: Body Text;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $body-text;
                    span {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $button-color;
                    }
                }
                .list-header {
                    background-color: #212425;
                    height: 50px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 0px 20px;
                    .div1 {
                        width: 17%;
                    }
                    .div2 {
                        width: 14%;
                    }
                    .div3 {
                        position: relative;

                        .file-upload {
                            position: absolute;
                            opacity: 0;
                            z-index: 1;
                            cursor: pointer;
                            input {
                                width: 100%;
                                cursor: pointer;
                            }
                        }
                    }
                    .count {
                        margin-left: 5px;
                        font-family: Poppins;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: center;
                        color: $success;
                    }
                    span {
                        margin-left: 5px;
                        //styleName: Caption 10;
                        font-family: Poppins;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 14px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #fff;
                    }
                }
                .email-input {
                    margin-top: 5px;
                    input {
                        border: 1px solid #d7dadb;
                        height: 42px;
                        border-radius: 0px;
                        &::placeholder {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $caption;
                        }
                    }
                    .css-b62m3t-container {
                        .css-1u9des2-indicatorSeparator {
                            display: none;
                        }
                    }
                }
                label {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $heading;
                }
                input[type="date"] {
                    border: 1px solid #d7dadb;
                    border-radius: 0px;
                    height: 40px;
                }
                input[type="date"]::-webkit-calendar-picker-indicator {
                    color: rgba(0, 0, 0, 0);
                    opacity: 1;
                    display: block;
                    background: url("../../../assets//date-icon.svg") no - repeat;
                    width: 20px;
                    height: 20px;
                    border-width: thin;
                }
                .generate-btn {
                    background-color: transparent;
                    border: 1px solid $button-color;
                    color: $button-color;
                    border-radius: 0px;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    width: 160px;
                    height: 40px;
                }
                .invite-btn {
                    background-color: $button-color;
                    border: none;
                    color: #fff;
                    border-radius: 0px;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    width: 200px;
                    height: 40px;
                }
                .template-divs {
                    margin-top: 5px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .template-div {
                        padding: 40px;
                        width: 49%;
                        border: 1px solid #d7dadb;
                        height: 108px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        p {
                            //styleName: Body text narrow;
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $body-text;
                        }
                        h4 {
                            //styleName: Action Text;
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $button-color;
                        }
                        & :nth-child(2) {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }

        .selected-test {
            margin-top: 4px;
            width: 24%;
            margin-left: 7px;
            box-shadow: 0px 1px 1px 0px #0000001a;
            min-height: 50px;
            border: 1px solid #d7dadb;
            border-radius: 4px;
            background-color: #fff;
            height: 500px;
            .selected-list-header {
                background: #212425;
                min-height: 88px;
                color: #fff;
                border-radius: 4px 4px 0px 0px;
                box-shadow: 0px 1px 1px 0px #0000001a;
                text-align: center;
                padding: 7px;
                h4 {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    color: $success;
                }
                h5 {
                    //styleName: Body text bold;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #fff;
                    padding: 4px 0px;
                }
                p {
                    //styleName: Caption 10;
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0px;
                    color: $caption;
                    margin-bottom: 0px;
                }
            }
            .selected-list {
                padding: 15px;
                height: calc(64vh - 140px);
                overflow-y: auto;
                & ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: white;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }
                h1 {
                    //styleName: Body text bold;
                    font-family: Poppins;
                    font-size: 14px !important;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $heading;
                }
                p {
                    //styleName: Caption 10;
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
                .selected-list-item {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    background: #f2f3f3;
                    // height: 48px;
                    border-radius: 2px;
                    justify-content: space-between;
                    padding: 7px 10px;
                    h1 {
                        //styleName: Body Text;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $heading;
                    }
                }
            }
            .add-btn {
                height: 40px;
                color: $button-color;
                border: 1px solid $button-color;
                font-weight: bold;
                border-radius: 0px;
                font-family: Poppins;
                font-size: 12px;
                font-weight: 700;
                line-height: 18px;
                text-align: center;
            }
        }
    }
    .test-preview {
        margin-left: 0px;
        margin-right: 0px;
    }

    .img-circle {
        width: 20px;
        height: 20px;
        background-color: #878f92;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .gappeo-question-list {
        .question-list-header {
            background-color: #fff;
            box-shadow: 0px 1px 1px 0px #0000001a;
            // height: 160px;
            padding: 15px 30px;
            h4 {
                font-family: Poppins;
                font-size: 24px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0px;
                text-align: left;
                color: $heading;
            }
            .done-btn {
                background-color: $button-color;
                color: #fff;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
                width: 120px;
                height: 40px;
                border: none;
            }
            .has-search {
                position: relative;
                width: 75%;
                .form-control-feedback {
                    position: absolute;
                    z-index: 0;
                    display: block;
                    height: 2.375rem;
                    line-height: 2.375rem;
                    text-align: center;
                    pointer-events: none;
                    color: $caption;
                    top: 0px;
                    width: 24px;
                    left: 9px;
                }

                .form-control {
                    padding-left: 2.3rem;
                    height: 38px;
                    border: 1px solid #d7dadb;
                    & ::placeholder {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $caption;
                    }
                    & ::-moz-placeholder {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $caption;
                    }
                    & ::-webkit-input-placeholder {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $caption;
                    }
                }
            }
            h5 {
                //styleName: Action Text;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
                color: $caption;
            }
        }
        .question-list1 {
            margin-top: 5px;
            box-shadow: 0px 1px 1px 0px #0000001a;
            background-color: #fff;
            height: 80px;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            padding: 20px 40px;
            div {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color: $body-text;
                &:nth-child(1) {
                    width: 5%;
                }
                &:nth-child(2) {
                    width: 55%;
                }
                &:nth-child(3) {
                    width: 35%;
                    text-align: center;
                }
                &:nth-child(3) {
                    width: 35%;
                    text-align: center;
                }
            }
        }
        .empty-list {
            background-color: #fff;
            margin-top: 5px;
            box-shadow: 0px 1px 1px 0px #0000001a;
            border-radius: 4px;
            padding: 25px 18px;
            text-align: center;
            padding-bottom: 50px;
            p {
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: center;
                color: $success;
            }
        }
    }
}

.candidate-list {
    .modal-body {
        background-color: #fff;
        position: relative;
        padding: 30px;

        h3 {
            //styleName: Section Heading;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
        }
        .candidate-table {
            overflow: auto;
            max-height: 400px;
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }
            table {
                tr {
                    margin-top: 10px;
                    // border: 1px solid #d7dadb;
                    background-color: #f2f3f3;
                    td {
                        border-bottom: 7px solid #fff;
                        padding: 8px;
                        //styleName: Body Text;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: center;
                        color: $body-text;
                        border-radius: 2px;
                    }
                }
            }
        }

        .sticky-close {
            position: absolute;
            right: 10px;
            top: 10px;
            img {
                cursor: pointer;
            }
        }
    }
}

.add-test-content {
    width: 360px;
    .modal-body {
        position: relative;
        .sticky-close {
            position: absolute;
            right: 10px;
            top: 10px;
            img {
                cursor: pointer;
            }
        }
        padding: 30px;
        p {
            padding: 5px 0px;
            //styleName: Body Text;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            color: $heading;
        }
        label {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
        }
        button {
            background-color: $button-color;
            height: 40px;
            color: #fff;
            border: none;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            border-radius: 0px;
        }
        .css-16xfy0z-control {
            .css-1hb7zxy-IndicatorsContainer {
                .css-894a34-indicatorSeparator {
                    display: none;
                }
            }
        }
        .css-13cymwt-control {
            border-radius: 0px;
        }
        .css-16xfy0z-control {
            border-radius: 0px;
        }
        .css-1jqq78o-placeholder {
            font-size: 15px;
        }
        .css-1fdsijx-ValueContainer {
            height: 48px;
        }
    }
}

.template-details {
    background-color: #fff;
    padding: 20px;
    p {
        color: #000;
    }
    .template-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
            //styleName: Page Heading;
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: center;
            color: $heading;
        }
        button {
            background-color: $button-color;
            color: #fff;
            border: none;
            height: 40px;
            padding: 0px 20px;
        }
    }
    .template-desc {
        .template-image {
            position: relative;
            .template-text {
                position: absolute;
                top: 30%;
                left: 45%;
                transform: translate(-10%, -10%);
                h3 {
                    font-family: Poppins;
                    font-size: 24px;
                    font-weight: 900;
                    line-height: 36px;
                    letter-spacing: 0.24em;
                    text-align: left;
                    color: $button-color;
                }
                p {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #fff;
                }
            }
        }
        label {
            color: #000;
            font-weight: 500;
            margin-bottom: 5px;
            margin-top: 10px;
        }
        .preview-btn {
            border: none;
            color: $button-color;
            background: transparent;
            font-weight: bold;
        }
        margin-top: 80px;
    }
}

.preview-modal {
    position: relative;
    .sticky-close {
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }
}

.intro-modal {
    width: 300px;
    .modal-content {
        border-radius: 9px;
        box-shadow: 0px 1px 40px 0px #00000066;
    }
    .modal-body {
        border-radius: 8px;
        background-color: #212425;
        padding: 20px;
        .sticky-close {
            position: absolute;
            right: 10px;
            top: 5px;
            img {
                cursor: pointer;
            }
        }
        h5 {
            margin-top: 5px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: #fff;
        }
        .ok {
            margin-top: 30px;
            button {
                width: 64px;
                // height: 24px;
                color: #fff;
                border: 1px solid #fff;
                background-color: transparent;
                font-family: Poppins;
                font-size: 12px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
            }
        }
    }
}

.create-question-main {
    .labeltext {
        font-size: 14px !important;
        color: #3d4143;
        font-family:
            Poppins,
            sans - serif !important;
        font-weight: 700;
        text-align: left;
    }
    .css-1xc3v61-indicatorContainer {
        display: none;
    }
    .btn-txt1 {
        background: $button-color;
        color: #fff;
        border: none;
        height: 45px;
    }
    .form-check- input:checked {
        background-color: $button-color;
        border-color: $button-color;
    }

    //rte

    .jodit-popup {
        z-index: 5 !important;
        transform: none !important;
    }
    .jodit-container {
        width: 100% !important;
        span {
            font-weight: normal;
            color: #222222;
            margin-bottom: 0px;
        }
        p {
            font-weight: normal;
            color: #222222;
            margin-top: 0px;
            font-size: 16px;
            margin: 2px;
        }
    }
    .jodit-toolbar__box {
        .jodit-ui-group__fullsize {
            display: none;
        }
    }
    .jodit-placeholder {
        color: #a5a5a5 !important;
        margin-bottom: 0px !important;
    }
    .jodit-workplace {
        min-height: 100px !important;
        // height: 200px !important;
        .jodit-wysiwyg {
            // min-height: 50px !important;
            ul {
                margin-left: 20px !important;
                padding: 0px !important;
            }
            ol {
                margin-left: -20px !important;
            }
        }
    }

    .jodit-status-bar {
        display: none;
        .jodit-toolbar-button_size_tiny {
            display: none;
        }
    }
    .jodit-status-bar__item > span {
        color: #222222 !important;
        margin: 5px !important;
        font-family: Roboto !important;
    }
    .jodit-ui-group__superscript {
        display: block;
    }

    .options-holder {
        .jodit-container {
            width: 100% !important;
        }
    }
}

@media (max-width: 767px) {
    .gappeo-test-results {
        .gappeo-mobile-header1 {
            margin-top: -25px;
            z-index: 6;
            position: relative;
            .selected-header {
                background-color: #f2f3f3;
                height: 88px;
                color: #fff;
                border-radius: 4px 4px 0px 0px;
                box-shadow: 0px 1px 1px 0px #0000001a;
                text-align: left;
                padding: 20px;
                h3 {
                    //styleName: Section Heading;
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $heading;
                }
                .circle {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: $heading;
                }
                .active {
                    background-color: $success !important;
                }
                h5 {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #fff;
                    padding: 4px 0px;
                }
                .outline-btn {
                    border: none;
                    padding: 0px 25px;
                    background-color: $button-color;
                    border-radius: 0px;
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #fff;
                }
            }
        }
        .gappeo-test-lists {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;
            .gappeo-test-data {
                width: 100%;
                padding-bottom: 20px;
                .empty-list {
                    padding: 25px 5px;
                    font-size: 14px;
                    text-align: center;
                    h3 {
                        font-size: 16px;
                    }
                }
                .gappeo-mobile-header {
                    .selected-list-header {
                        border-radius: 0px;
                    }
                }
                .skill-search {
                    margin-left: 10px;
                    margin-right: 10px;
                    margin-top: 15px;
                    background: transparent;
                    box-shadow: none;
                    padding: 10px 15px 5px 15px;
                    display: flex;
                    flex-direction: row;
                    .has-search {
                        width: 70%;
                        .form-control-feedback {
                            top: 5px;
                        }
                        .form-control {
                            border: 1px solid #d7dadb;
                            box-shadow: 0px 4px 25px 0px #00000026;
                            height: 48px;
                        }
                    }
                }
                .level-tab {
                    // height: 24px;
                    color: #fff;
                    background-color: $caption;
                    border-radius: 20px;
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: center;
                    padding: 10px 20px;
                }
                .active {
                    background-color: $button-color !important;
                }
                .select-list {
                    width: 90%;
                    margin-left: 20px;
                    margin-right: 20px;
                    box-shadow: none;
                    background-color: transparent;
                    .list-skill {
                        .list-skill-item {
                            position: relative;
                            width: 100%;
                            margin-right: 0px;
                            .skill-type {
                                position: absolute;
                                top: 0px;
                                right: 0px;
                                width: 60%;
                            }
                        }
                    }
                }
            }
            .selected-test {
                display: none;
            }
            .gappeo-add-question {
                width: 100%;
                .add-question {
                    width: 100%;
                }
            }
            .gappeo-mobile-header {
                .selected-list-header {
                    background: #212425;
                    min-height: 88px;
                    color: #fff;
                    border-radius: 4px 4px 0px 0px;
                    box-shadow: 0px 1px 1px 0px #0000001a;
                    text-align: left;
                    padding: 20px;
                    h4 {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $success;
                    }
                    h5 {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #fff;
                        padding: 4px 0px;
                    }
                    .outline-btn {
                        height: 32px;
                        border: 1px solid #fff;
                        background-color: transparent;
                        border-radius: 0px;
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #fff;
                    }
                }
            }
        }
        .gappeo-question-list {
            margin-top: 75px;
            .question-list-header {
                margin-top: -75px;
                position: relative;
                padding: 15px;
                h4 {
                    font-size: 16px;
                }
                h5 {
                    margin-top: 10px;
                }
            }
            .question-list {
                padding: 20px 20px;
            }
        }
        .gappeo-preview {
            .invite-details {
                padding: 17px !important;
                .template-divs {
                    .template-div {
                        min-height: 108px;
                        height: unset;
                        width: 100% !important;
                        padding: 10px !important;
                        h4 {
                            font-size: 13px !important;
                        }
                        & :nth-child(2) {
                            margin-left: 0px !important;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
    .update-modal {
        width: unset;
        .modal-content {
            border-radius: 8px;
            border: none;
        }
        .modal-header {
            position: relative;
            padding: 0.7rem 0.7rem;
            text-align: center;
            display: block;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            h5 {
                color: #fff;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;

                p {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #fff;
                    margin-top: 4px;
                }
            }
            .sticky-close {
                position: absolute;
                right: 15px;
                top: 15px;
                cursor: pointer;
            }
        }
        .modal-body {
            .css-b62m3t-container {
                margin-top: 0.5rem;
                .css-1okebmr-indicatorSeparator {
                    display: none;
                }
                .css-13cymwt-control {
                    border-color: $chart-color !important;
                    box-shadow: 0px 0px 0px 1px $chart-color !important;
                }
                input:focus {
                    box-shadow: none !important;
                }
                .css-t3ipsp-control {
                    border: 1px solid $chart-color;
                    border-radius: 4px;
                }
            }
            .save-btn {
                text-align: center;
                margin-top: 30px;
                button {
                    width: 150px;
                    height: 40px;
                    background-color: $button-color;
                    border: none;
                    border-radius: 4px;
                    color: #fff;
                }
            }
        }
    }
    .selected-test-drawer {
        .MuiPaper-root {
            height: 100vh !important;
            .selected-list-header {
                background: #212425;
                min-height: 150px;
                color: #fff;
                border-radius: 0px 0px;
                box-shadow: 0px 1px 1px 0px #0000001a;
                text-align: center;
                padding: 7px;

                .close {
                    position: absolute;
                    left: 20px;
                }
                h4 {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    color: $success;
                }
                h5 {
                    //styleName: Body text bold;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #fff;
                    padding: 4px 0px;
                }
                p {
                    //styleName: Caption 10;
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0px;
                    color: $caption;
                    margin-bottom: 0px;
                }
            }
            .selected-list {
                padding: 40px;
                height: calc(64vh - 95px);
                overflow-y: auto;
                & ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: white;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }
                h1 {
                    //styleName: Body text bold;
                    font-family: Poppins;
                    font-size: 14px !important;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $heading;
                }
                p {
                    //styleName: Caption 10;
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
                .selected-list-item {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    background: #f2f3f3;
                    // height: 48px;
                    border-radius: 2px;
                    justify-content: space-between;
                    padding: 7px 10px;
                    h1 {
                        //styleName: Body Text;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $heading;
                    }
                }
            }
        }
    }

    .px-5.create-question-main {
        margin-top: 70px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 40px !important;
    }
    .px-5.create-question-main.row.px-5 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .px-5.create-question-main.row.px-5 > .col-12 {
        padding: 0;
    }
    .px-5.create-question-main.row.col-2 {
        padding-left: 0;
    }
    .px-5.create-question-main.row.px-5 > .col-12 .form-check-inline {
        margin-bottom: 15px;
        font-size: 14px;
    }
    .right-ans-desc-main tr th {
        // width: 20%;
        text-align: left !important;
    }
    .w-25 {
        width: 25% !important;
    }
    .w-50 {
        width: 50% !important;
    }
    .right-ans-desc-main tr th :last-child {
        width: 58%;
    }
    .right-ans-desc-main tr th label.table-th {
        padding-left: 0;
    }
    .right-ans-desc-main tr td {
        text-align: left !important;
    }
    .right-ans-desc-main tr th label {
        color: #3d4143 !important;
    }
}

@media (min-width: 767px) and (max-width: 1368px) {
    .gappeo-test-results.gappeo-test-lists.gappeo-preview.invite-details.template-divs.template-div :nth-child(2) {
        margin-left: 15px;
    }
}
