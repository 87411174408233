@import "../../App.scss";

.bg-img {
    background-color: #f5f5fa;
    // background: url("../../assets/login-image1.jpg") no-repeat center center fixed;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}
.login-holder {
    font-family: Poppins, sans-serif !important;
    text-align: center;
    background: #fff;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
    padding: 30px 30px 30px;
    width: 360px;
    border-radius: 4px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    h4 {
        font-size: 20px;
        font-family: Poppins;
    }
    input {
        border: 1px solid $chart-color;
        height: 48px;
        border-radius: 0px;
        &:focus {
            border-color: $chart-color;
            box-shadow: none;
        }
    }
    .has-search {
        position: relative;
        .form-control-feedback {
            position: absolute;
            z-index: 0;
            display: block;
            height: 2.375rem;
            line-height: 2.375rem;
            text-align: center;
            pointer-events: none;
            color: #aaa;
            margin-top: 4px;
            width: 24px;
            margin-left: 9px;
        }

        .form-control {
            padding-left: 2.3rem;
        }
    }

    .login-button {
        background-color: $button-color;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        border: none;
        width: 100%;
        height: 48px;
    }
    .forgot-btn {
        color: $button-color;
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
        cursor: pointer;
        text-align: center;
    }
}
.error {
    text-align: left !important;
    color: red;
    font-size: 14px;
    margin: 5px 0px;
}
.otp-holder {
    width: 50%;
    text-align: center;
    h3 {
        font-size: 24px;
    }
    p {
        font-size: 14px;
    }
    .primary-button {
        background-color: $button-color;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        border: none;
        width: 100%;
        height: 48px;
    }

    .otp-fields {
        p {
            margin-bottom: 20px;
        }
        input {
            height: 48px;
        }
        .form-group {
            input {
                margin-bottom: 10px;
            }
        }
    }
}

.primary-button {
    background-color: $button-color;
    position: relative;
}

@media (max-width: 767px) {
    .login-banner {
        display: none;
    }
    .otp-holder {
        .otp-input {
            width: 40px !important;
        }
    }
}

// Media Query

@media (max-width: 767px) {
    .banner-header-content {
        padding-right: 15px !important;
        padding-bottom: 15px;
    }

    .login-main-holder {
        padding: 1rem !important;

        .banner-header-login {
            .logo-main-holder {
                margin: 0;
            }
            h1 {
                display: none;
            }
            p {
                display: none;
            }
        }

        .login-holder {
            width: 100% !important;
            .form-group {
                span {
                    top: -8px !important;
                    line-height: 100%;
                    text-align: center;
                    width: 100%;
                }
                input {
                    font-size: 14px;
                }
            }
        }
        .banner-header-content {
            .banner-creative {
                display: none;
            }
        }
    }
}

@media (min-width: 768px) {
    .login-container {
        padding-top: 43px;
    }
}

@media (max-width: 480px) {
    .login-holder {
        .otp-fields {
            .otp-input {
                width: 36px !important;
                height: 36px;
                margin: 2px !important;
            }
        }
    }
}
