@import "../../App.scss";

.coding-questions-list {
    .question-list-header {
        // background-color: #fff;
        // box-shadow: 0px 1px 1px 0px #0000001a;
        // height: 160px;
        padding: 15px 30px;
        h4 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
        }
        .done-btn {
            background-color: $button-color;
            color: #fff;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            width: 120px;
            height: 40px;
            border: none;
        }
        .has-search {
            position: relative;
            width: 75%;
            .form-control-feedback {
                position: absolute;
                z-index: 0;
                display: block;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: $caption;
                top: 0px;
                width: 24px;
                left: 9px;
            }

            .form-control {
                padding-left: 2.3rem;
                height: 38px;
                border: 1px solid #d7dadb;
                &::placeholder {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
                &::-moz-placeholder {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
                &::-webkit-input-placeholder {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
            }
        }
        h5 {
            //styleName: Action Text;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: $caption;
        }
    }
    .infinite-scroll-component__outerdiv {
        padding: 0px 30px;
    }
    .question-list {
        margin-top: 5px;
        // box-shadow: 0px 1px 1px 0px #0000001a;
        background-color: #fff;
        height: 80px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        border: 1px solid #d7dadb;

        div {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: $body-text;
            margin-top: 5px;
            &:nth-child(1) {
                width: 5%;
            }
            &:nth-child(2) {
                width: 25%;
            }
            &:nth-child(3) {
                width: 20%;
                text-align: center;
            }
            &:nth-child(3) {
                width: 20%;
                text-align: center;
            }
            &:nth-child(4) {
                width: 20%;
                text-align: center;
            }
            &:nth-child(5) {
                width: 20%;
                text-align: center;
            }
        }
    }
    .empty-list {
        background-color: #fff;
        margin-top: 5px;
        box-shadow: 0px 1px 1px 0px #0000001a;
        border-radius: 4px;
        padding: 25px 18px;
        text-align: center;
        padding-bottom: 50px;
        p {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: center;
            color: $success;
        }
    }
}
.css-13cymwt-control {
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
}
.css-t3ipsp-control {
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
}
.select-input {
    height: 40px;
}
.add-text {
    background-color: #fff;
    padding: 40px;
    font-family: poppins;
    label {
        font-weight: 600;
    }
    button {
        background-color: #009199;
        color: #fff;
        font-size: 14px;
        width: 160px;
        height: 40px;
        border: none;
        text-align: center;
    }
}
