@import "../../App.scss";

.candidate-test-holder {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .thank-screen {
        background-color: #fff;
        height: 400px;
        text-align: center;
        padding: 40px 150px;
        h3 {
            margin-top: 15px;
            line-height: 32px;
            font-family: Poppins, sans-serif !important;
            color: #3d4143;
            font-size: 25px;
            font-weight: 600;
            text-align: center;
        }
    }
    //summary
    .test-summary {
        background-color: #fff;
        padding: 20px 30px;

        h3 {
            //styleName: Section Heading;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: #000000;
        }
        .test-list {
            width: 48%;
            .list-item {
                width: 80%;
                border: 1px solid #d7dadb;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 12px;
                margin-top: 10px;
                h4 {
                    //styleName: Body text bold;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $heading;
                }
                p {
                    //styleName: Caption;
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
            }
            h2 {
                //styleName: Page Heading;
                font-family: Poppins;
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0px;
                text-align: left;
                color: #000000;
            }
            p {
                //styleName: Body Text;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
                color: $body-text;
            }
            .candidate-input {
                margin-top: 20px;
                input {
                    background-color: $dark-background;
                    border-radius: 0px;
                    height: 40px;
                    &::placeholder {
                        color: #d7dadb;
                        //styleName: Body Text;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                    }
                }
                button {
                    background-color: $button-color;
                    color: #fff;
                    border-radius: 0px;
                    height: 40px;
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: center;
                }
            }
        }
    }
    .next-btn {
        width: 220px;
        height: 40px;
        background-color: $button-color;
        color: #fff;
        border-radius: 0px;
        border: none;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: center;
    }
    //Diagnostics
    .diagnostic-holder {
        background-color: #fff;
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 1px 0px #0000001a;

        .diagnostic-text {
            height: 569px;
            width: 30%;
            padding: 20px 30px;
            box-shadow: 0px 1px 1px 0px #0000001a;
            // border: 0px 1px 0px 0px;
            border-right: 1px solid #d7dadb;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            h4 {
                //styleName: Section Heading;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color: $heading;
                margin: 15px 0px;
            }
            p {
                //styleName: Body Text;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
                color: $body-text;
            }
        }
        .dignostic-check {
            width: 70%;
            padding: 50px;
            p {
                //styleName: Body text bold;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
                color: $heading;
                margin-bottom: 10px;
            }
            .accordion {
                .accordion-item {
                    margin-top: 5px;
                    border-radius: 4px;
                    #headingTwo {
                        border-top: 1px solid #d7dadb !important;
                    }
                    .accordion-header {
                        .accordion-button.collapsed {
                            background-color: #f2f3f3;
                        }
                        .accordion-button {
                            //styleName: Body text bold;
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $heading;

                            &::after {
                                display: none;
                            }
                        }
                        .accordion-button:not(.collapsed) {
                            color: $heading;
                            background-color: transparent;
                            box-shadow: none;
                        }
                    }
                    .accordion-body {
                        position: relative;
                        .check-item {
                            width: 23%;
                            border: 1px solid #d7dadb;
                            margin-left: 10px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                            padding: 12px 15px;
                            p {
                                //styleName: Body text narrow;
                                font-family: Poppins;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 16px;
                                letter-spacing: 0px;
                                text-align: center;
                                color: $body-text;
                                margin-top: 5px;
                            }
                            &:nth-child(1) {
                                margin-left: 0px;
                            }
                        }
                        // .capture-btn {
                        //     position: absolute;
                        //     bottom: 0px;
                        //     top: px;
                        //     border: none;
                        //     background: transparent;
                        // }
                    }
                }
            }
        }
    }

    .test-detail-progress {
        background-color: #fff;
        padding: 20px 30px;

        h1 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            color: #000000;
        }
        p {
            //styleName: Body Text;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            color: $body-text;
        }
        .test-list {
            width: 48%;
            .progress-circular {
                height: 85%;
            }
            .list-item {
                width: 80%;
                border: 1px solid #d7dadb;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 12px;
                margin-top: 10px;
                h4 {
                    //styleName: Body text bold;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $heading;
                }
                p {
                    //styleName: Caption;
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
            }
            h2 {
                //styleName: Page Heading;
                font-family: Poppins;
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0px;
                text-align: left;
                color: #000000;
            }
            p {
                //styleName: Body Text;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
                color: $body-text;
            }
            label {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
                color: #000000;
            }
            .next-btns {
                display: flex;
                justify-content: flex-start;
                margin-top: 20px;
                button {
                    background-color: $success;
                    color: #fff;
                    height: 48px;
                    padding: 10px;
                    border: none;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: center;
                    &:nth-child(2) {
                        margin-left: 10px;
                        background: transparent;
                        color: $body-text;
                        border: 1px solid $body-text;
                    }
                }
            }
        }
    }
    .test-view {
        background-color: #fff;
        padding: 100px;
        min-height: 600px;

        h6 {
            //styleName: Caption;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: #000000;
        }
        h5 {
            //styleName: Section Heading;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
        }
        p {
            //styleName: Caption;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
        }
        h3 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
        }
        .ques-option {
            background-color: #f2f3f3;
            width: 300px;
            color: #000000;
            //styleName: Body Text;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            padding: 17px;
            display: flex;
            align-items: center;
            margin-top: 5px;
        }
        .select-option {
            border: 1px solid $success;
        }
        .question-option {
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
                width: 62px;
                height: 62px;
                filter: grayscale(100%);
            }
            span {
                font-family: Poppins;
                font-size: 15px;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                color: #000000;
            }
        }
        .active {
            img {
                filter: grayscale(0%) !important;
            }
        }
    }
    .timer-div {
        position: absolute;
        right: 0px;
        top: 145px;
        // timer
        /*----------------------*/
        .base-timer {
            position: relative;
            width: 150px; /* Adjust the width */
            height: 150px; /* Adjust the height */
            margin: auto;
        }

        .base-timer__svg {
            transform: scaleX(-1);
        }

        .base-timer__circle {
            fill: $success;
            stroke: none;
        }

        .base-timer__path-elapsed {
            stroke-width: 3px; /* Adjust the stroke width */
            stroke: #efefef;
        }

        .base-timer__path-remaining {
            stroke-width: 2px; /* Adjust the stroke width */
            stroke-linecap: round;
            transform: rotate(90deg);
            transform-origin: center;
            transition: 1s linear all;
            fill-rule: nonzero;
            stroke: currentColor;
        }

        .base-timer__path-remaining.black {
            color: #000000;
        }

        .base-timer__path-remaining.orange {
            color: orange;
        }

        .base-timer__path-remaining.red {
            color: red;
        }

        .base-timer__label {
            position: absolute;
            width: 145px; /* Adjust the width */
            height: 115px; /* Adjust the height */
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Poppins;
            font-size: 24px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            color: #fff;
        }
    }
}

.cookie-modal {
    width: 250px;
    .modal-content {
        border-radius: 10px;
    }
    .modal-body {
        background-color: $dark-background;
        padding: 30px 35px;
        border-radius: 8px;
        p {
            font-family: Poppins;
            font-size: 10px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            color: #ffffff;
        }
        button {
            width: 180px;
            margin-top: 10px;
            height: 35px;
            border: none;
            background-color: $button-color;
            border-radius: 0px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            color: #fff;
            &:nth-child(2) {
                border: 1px solid #fff;
                background-color: transparent;
            }
            &:nth-child(3) {
                background-color: transparent;
            }
        }
    }
}

.warning-modal {
    width: 32%;
}

@media (max-width: 767px) {
    .candidate-test-holder {
        .candidate-banner {
            display: none;
        }
        .test-summary {
            .test-list {
                padding: 20px;
            }
            #accordionExample {
                .accordion-button {
                    background-color: #fff;
                    color: $heading;
                    //styleName: Body text bold;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    display: block;
                    box-shadow: none;
                    width: 90%;
                    padding: 1rem 0rem;
                    padding-left: 80px;
                    p {
                        //styleName: Body text narrow;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $body-text;
                    }
                }
                .accordion-button:not(.collapsed)::after {
                    position: absolute;
                    top: 25px;
                    left: 25px;
                }

                .accordion-button:after {
                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
                }

                .accordion-body {
                    padding: 0rem;
                    .test-list {
                        padding: 0px;
                        width: 100%;
                        .list-item {
                            width: 100%;
                            border: none;
                            border-bottom: 1px solid #d7dadb;
                        }
                    }
                }
            }
        }
        .diagnostic-holder {
            // flex-wrap: wrap;
            flex-direction: column;
            .diagnostic-text {
                border-right: none;
                width: 100%;
                height: 300px;
                padding: 50px;
            }
            .dignostic-check {
                width: 100%;
                padding: 0px;
                padding-bottom: 30px;
                .accordion .accordion-item {
                    .accordion-button {
                        padding-left: 52px;
                    }
                    .accordion-body {
                        padding: 0rem 3.25rem;
                        .check-item {
                            margin-top: 10px;
                            width: 100%;
                            margin-left: 0px;
                            flex-direction: row;
                            justify-content: flex-start;
                            p {
                                margin-left: 10px;
                                width: 75%;
                                text-align: left;
                            }
                        }
                    }
                }
                .next-btn {
                    width: 100%;
                }
            }
        }
        .test-detail-progress {
            padding: 0px;
            padding-top: 30px;
            h1 {
                padding: 15px 30px;
                text-align: left;
            }
            p {
                padding: 0px 30px;
                text-align: left;
            }
            .test-list {
                width: 100%;
                min-height: 270px;
                .list-item {
                    width: unset;
                    padding: 0px 15px;
                    p {
                        padding: 0px;
                    }
                }
                .next-btns {
                    justify-content: center;
                    margin-bottom: 20px;
                    button {
                        padding: 10px 60px;
                    }
                }
            }
        }
        .test-view {
            height: 600px;
            overflow: auto;
            padding: 40px;
            padding-top: 70px;
        }
        .timer-div {
            top: 75px;
            .base-timer {
                width: 100px;
                height: 100px;
            }
            .base-timer__label {
                font-size: 13px;
                width: 100px;
                height: 80px;
            }
        }
    }
}
