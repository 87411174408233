@import "../../App.scss";

.gappeo-my-tests {
    .caption {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: $heading;
    }
    .search-tests {
        background-color: #fff;
        padding: 15px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // align-items: center;
        .type-btn {
            width: 120px;
            height: 40px;
            border: 1px solid #d7dadb;
            border-radius: 4px;
            font-size: 13px;
            font-weight: 400;
            color: $heading;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .active-type {
            background-color: #b1e0c2;
            border: 1px solid #40c572;
            font-weight: 600;
        }
        .has-search {
            position: relative;
            width: 100%;

            .form-control-feedback {
                position: absolute;
                z-index: 0;
                display: block;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
                top: 2px;
                width: 24px;
                left: 8px;
            }

            .form-control {
                padding-left: 2.3rem;
                height: 40px;
            }
            input {
                // border: none;
                border-right: 1px solid #d7dadb;
                border-radius: 0px;
                &::placeholder {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
            }
        }
        select {
            margin-left: 10px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #000000 !important;
            width: 95%;
            padding-left: 0.5rem;
            // border-left: 1px solid #d7dadb !important;
            border-radius: 0px;
            border: 1px solid #d7dadb;
            height: 40px;
            box-shadow: none !important;
            background-color: #fff;
            cursor: pointer;
            color: $body-text;
            &:focus {
                outline: none !important;
            }
        }
    }
    .table-admin {
        .list-skill {
            width: 100%;
            padding: 0px 16px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 5px;
            max-height: 450px;
            min-height: 175px;
            overflow: auto;
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }
            input[type="checkbox"] {
                width: 18px;
                height: 18px;
            }
            .list-skill-item {
                border: 1px solid #d7dadb;
                height: 80px;
                border-radius: 4px;
                width: 23.5%;
                padding: 10px;
                display: flex;
                align-items: center;
                margin-right: 12px;
                margin-top: 5px;
                cursor: pointer;
                & :nth-child(3n) {
                    margin-right: 0px;
                }
                .skill-type {
                    text-transform: capitalize;
                    // width: 65%;
                    border-radius: 2px;
                    padding: 4px 8px;
                    background-color: #f2f3f3;
                    //styleName: Caption 10;
                    font-family: Poppins;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: $heading;
                }
                h4 {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #3e67b7;
                }
                p {
                    //styleName: Caption;
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $body-text;
                }
            }
        }
    }
    .table-admin .table tbody tr td {
        h5 {
            //styleName: Body text narrow;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: $body-text;
        }
        .create {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 700;
            line-height: 14px;
            text-align: center;
            color: $button-color;
        }
    }
}
.sample-question-modal {
    .modal-content {
        border: none !important;
    }
    .modal-header {
        position: relative;
        padding: 10px;
        text-align: center;
        justify-content: center;
        background-color: $button-color;
        color: #fff;
        font-family: Poppins;
        border: 1px solid $button-color;
    }
    .sticky-close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
    .ques-div {
        padding: 10px;
        overflow: auto;
        & ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: white;
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            max-height: 10px;
            background-color: #e9e8e8;
        }
        .card-header {
            padding: 0.5rem !important;
            background-color: #fff;
            font-size: 14px;
            font-family: poppins;
            span {
                width: 100% !important;
            }
        }
        .card-body {
            padding: 1rem 0.3rem;
            .ques-options {
                list-style: none;
                li {
                    margin-bottom: 10px;
                    width: 100%;
                    border: 1px solid #d7dadb;
                    box-shadow: 0px 0px 4px 0px #00000026;
                    padding: 8px;
                    user-select: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 2px;
                    font-size: 14px;
                }
                .correct {
                    background-color: $success;
                    color: #fff !important;
                    border: 1px solid $success !important;
                    p {
                        color: #fff !important;
                        span {
                            color: #fff !important;
                        }
                    }
                }
                .incorrect {
                    background-color: #fc3a3a;
                    color: #fff !important;
                    p {
                        color: #fff !important;
                        span {
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }
}
