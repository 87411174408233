@import "../../App.scss";

.settings-holder {
    .pricing-table .block-heading {
        padding-top: 50px;
        margin-bottom: 40px;
        text-align: center;
    }
    .buy-btn {
        background-color: $button-color;
        color: #fff;
        width: 180px;
        height: 40px;
        border: none;
    }

    .gappeo-pricing {
        width: 99%;
        .pricing-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100vh;
            width: 62%;
            p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 32px;
                color: #51488c;
                letter-spacing: 5px;
            }
            h1 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 56px;
                line-height: 56px;
                color: #000000;
            }
            h2 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                color: #242326;
            }
            button {
                color: #535259;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                background: #ffffff;
                border: 1px solid #6d20d6;
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
                border-radius: 4px;
                width: 181px;
                height: 48px;
            }
        }
        .pricing-tables {
            text-align: center;
            button {
                margin-top: 10px;
                background-color: transparent !important;
                color: #fff;
                padding: 5px 9px;
                border: 1px solid #fff;
                border-radius: 20px;
                a {
                    text-decoration: none !important;
                }
            }
            .text-note {
                text-align: left;
                color: #82818c;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
            }
            .feature-table {
                margin-left: 21.2rem;
                margin-right: 21.2rem;
                max-width: 1153px;
                margin: 0 auto;
                margin-top: 30px;
                // .table-striped > tbody > tr:nth-of-type(even) {
                //     background-color: #e0f0ff;
                // }

                table {
                    width: 100%;
                    border-collapse: collapse;
                    border-spacing: 0;
                    table-layout: auto;
                    tbody > tr:nth-of-type(even) td {
                        background-color: #f5f5fa;
                    }
                    tbody {
                        tr td {
                            height: 48px;
                            vertical-align: middle;
                            font-size: 14px;
                            border-bottom-width: 0px;

                            img {
                                width: 20px;
                                height: 20px;
                            }
                            &:nth-child(1) {
                                text-align: left;
                            }
                        }
                    }
                    .feature {
                        width: 180px;
                        color: #fff;
                        background: #8b7df2;
                        border-radius: 4px 4px 0px 0px;
                        padding: 12px;
                    }
                    .plan-box {
                        width: 168px;
                        // height: 92px;
                        padding: 20px;
                        padding-top: 30px !important;
                        background-color: #e7c15f;
                        margin-left: 30px;
                        border-radius: 4px 4px 0px 0px;
                        // display: flex;
                        // justify-content: center;
                        // flex-direction: column;
                        // align-items: center;
                        p {
                            font-family: "Poppins";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            color: #fff;
                        }
                        h1 {
                            font-family: "Poppins";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 40px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .elite-pricing {
            width: unset;
            // margin-right: -2px;
            .feature-table {
                margin-bottom: 20px;
                .feature {
                    width: unset !important;
                }
                table {
                    text-align: center;
                }
                table tr th:first-child {
                    z-index: 5 !important;
                }
                .plan-table {
                    width: 100%;
                    min-height: 100px;
                    overflow: scroll;
                }
                table th,
                table td {
                    white-space: nowrap;
                    padding: 10px 20px;
                }
                table tr .fixed-col {
                    position: sticky;
                    width: 100px;
                    min-width: 130px;
                    max-width: 190px;
                    left: -1px;
                    z-index: 5;
                    background: #fff;
                    overflow: hidden;
                }

                table thead th:first-child,
                table tr th:first-child,
                table td:first-child {
                    position: sticky;
                    width: 100px;
                    min-width: 130px;
                    max-width: 190px;
                    left: -1px;
                    z-index: 5;
                    background: #fff;
                    overflow: hidden;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 2;
                    // -webkit-box-orient: vertical;
                }

                table tr th {
                    position: sticky;
                    top: 0;
                    z-index: 4;
                    background: #fff;
                }
            }
        }
    }

    .nav-tabs .nav-link {
        color: #495057;

        &:hover {
            background-color: $button-color;
            color: #fff;
        }
    }

    .pricing-table .block-heading h2 {
        color: #3b99e0;
    }

    .pricing-table .block-heading p {
        text-align: center;
        max-width: 420px;
        margin: auto;
        opacity: 0.7;
    }
    .pricing-table .heading h3 {
        font-size: 18px;
        line-height: 1.4;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        letter-spacing: normal;
    }
    .btn-txt1 {
        background-color: $button-color;
        color: #fff;
        border: none;
        height: 38px;
        width: 200px;
    }
    .pricing-table .item {
        background-color: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
        /* border-top: 2px solid #5ea4f3; */
        padding: 30px;
        overflow: hidden;
        position: relative;
    }

    .pricing-table .col-md-5:not(:last-child) .item {
        margin-bottom: 30px;
    }

    .pricing-table .item button {
        font-weight: 600;
    }

    .pricing-table .ribbon {
        width: 160px;
        height: 32px;
        font-size: 12px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
        background: #4dbe3b;
        transform: rotate(45deg);
        position: absolute;
        right: -42px;
        top: 20px;
        padding-top: 7px;
    }

    .pricing-table .item p {
        text-align: left;
        margin-top: 20px;
        color: #000 !important;
        opacity: 0.7;
    }

    .pricing-table .features .feature {
        font-weight: 500;
    }

    .pricing-table .features h4 {
        text-align: left;
        font-size: 14px;
        padding: 5px;
        color: #555;
        font-weight: 400;
    }

    .pricing-table .price h4 {
        margin: 15px 0;
        color: #000;
        font-size: 56px;
        line-height: 1.1;
        font-weight: 500;
        margin-right: 8px;
    }

    .pricebtn {
        border: none;
        outline: none;
        height: 45px;
        background-color: #000;
        color: #fff;
        font-size: 14px;
        width: 100%;
        margin-top: 5%;
        margin-bottom: 10%;
        display: inline-block;
        text-decoration: none;
        text-align: center;
        padding-top: 5%;
    }

    .pricebtn:hover {
        background-color: #009199;
    }
    :host .btn-videotxt1 {
        border: none;
        outline: none;
        display: inline-block;
        background-color: transparent;
        color: #009199;
        font-size: 16px;
        width: 100% !important;
        text-align: left !important;
        padding-left: 0% !important;
    }
    @media (max-width: 767px) {
        .payment-plans-main {
            padding: 20px 10px !important;
        }
        .payment-plans-main > .row > .col-3 {
            width: 50%;
        }
        .payment-plans-main > .row > .col-6 h1 {
            font-size: 20px;
            padding-top: 7px;
        }
        section.pricing-table > div {
            max-width: 100%;
        }
        section.pricing-table img.w-50 {
            width: 50px !important;
        }
        section.pricing-table .pricebtn {
            margin: 0;
            height: auto;
            width: auto;
            padding: 0;
        }
        section.pricing-table a.pricebtn {
            display: inline-block;
            padding: 15px 20px;
            margin-bottom: 20px;
            width: 100%;
        }
        div#myTabContent #profile .col-5 button {
            width: 100%;
        }
        div#myTabContent #profile .col-7 input#couponcode {
            padding: 10px 10px;
        }
    }
    @media (max-width: 480px) {
        .payment-plans-main > .row > .col-6 h1 {
            font-size: 16px;
        }
    }
    @media (max-width: 440px) {
        ul#myTab li button span.labletext {
            font-size: 12px !important;
        }
        ul#myTab li button {
            padding-left: 7px;
            padding-right: 7px;
        }
    }
}
.account-tabs {
    margin: 20px 0;
    button {
        background-color: #fff;
        border: 1px solid $caption;
        box-sizing: border-box;
        border-radius: 20px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $caption;
        height: 35px;
        padding: 5px 20px;
    }
    .active {
        color: #fff !important;
        background-color: $button-color;
        border: none;
    }
}

.account-holder {
    background-color: #fff;
    margin-top: 10px;
    padding: 25px 50px;
    width: 86%;
    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        color: #272727;
        font-family: Poppins;

        .select-input {
            padding: 0;
            border: 0;
        }
    }
    h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: center;
        color: #272727;
        font-family: Poppins;
    }
    h5 {
        padding-bottom: 20px;
        //styleName: Page Heading;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
        color: $heading;
    }
    .warn-text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #9b9aa3;
    }
    label {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        width: 100%;
    }
    input {
        // width: 53%;
        border: 1px solid $chart-color;
        // text-transform: uppercase;
        &:focus {
            outline: none !important;
            border-color: $chart-color;
            box-shadow: none;
        }
    }
    .form-control:disabled,
    .form-control[readonly] {
        background-color: #f7f7fa;
    }
    .program-image {
        width: 139px;
        height: 78px;
        background-color: #f7f7fa;
        margin-top: 10px;
        margin-right: 15px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: $button-color;
        margin-bottom: 10px;

        input {
            cursor: pointer;
        }
        img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }
        .file-upload {
            position: absolute;
            left: 0px;
            height: 30px;
            width: 100%;
            opacity: 0;
            cursor: pointer;
            top: -2px;
        }
    }
    .save-btn {
        margin-top: 10px !important;
        button {
            border: none;
            background-color: $button-color;
            min-width: 140px;
            height: 32px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            border-radius: 2px;
            &:nth-child(2) {
                border: 1px solid #f8f8f8;
                background: none;
                color: #222222;
                margin-left: 5px;
            }
        }
    }
    .template-header {
        margin: 20px 0 50px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
            //styleName: Page Heading;
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: center;
            color: $heading;
        }
        button {
            background-color: $button-color;
            color: #fff;
            border: none;
            height: 40px;
            padding: 0px 20px;
        }
        .back-arrow {
            cursor: pointer;
        }
    }
}
.edit-buttons {
    width: 105px;
    background-color: $button-color;
    color: #fff;
    border: none;
    height: 40px;
    border-radius: 20px;
    padding: 0px 20px;
}

@media (max-width: 767px) {
    .settings-holder {
        margin-top: 70px;
    }
    .account-holder {
        margin-top: 75px;
        width: 100%;
    }
}
