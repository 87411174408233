// Define styles for loader container
.gp-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

// Define styles for loader
.gp-loader {
  border: 6px solid #f3f3f3;
  /* Light grey */
  border-top: 6px solid #009199;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: gp-loader-animation 1s linear infinite;
}
.gappeo-my-tests {
  .my-search {
    background-color: #fff;
    padding: 20px;
  }
}
