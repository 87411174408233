@import "../App.scss";

.gappeo-holder {
    .layout-container {
        margin-left: 132px;
        margin-right: 132px;
        margin-top: 90px;
    }
    .header-container {
        position: fixed;
        top: 0px;
        z-index: 5;
        width: 100%;
        height: 71px;
    }
    .user-menu-section {
        display: none;
    }
    .menus .active {
        background-color: $button-color;
        color: #fff;
        margin-top: 5px;
        border-radius: 50px;
        padding: 5px 10px;
        font-weight: 700 !important;
    }

    .dropdown-toggle {
        white-space: nowrap;
    }
    ul {
        margin-bottom: 0px;
    }

    .menus li {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        text-align: left;
        color: #fff;
        margin-top: 5px;
        padding: 5px 10px;
        cursor: pointer;
    }
}
//Candidate-header
.candidate-holder {
    background: #f2f2f3;
    height: 100vh;
    .candidate-header {
        width: 100%;
        height: 72px;
        padding: 5px 30px;
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
            //styleName: Page Heading;
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
            color: #fff;
        }
        .user-name {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: #fff;
        }
        h6 {
            //styleName: Body text bold;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: #fff;
        }
    }
    .layout-container {
        margin-left: 132px;
        margin-right: 132px;
        margin-top: 80px;
    }
}

@media (max-width: 767px) {
    .gappeo-holder {
        background-color: #fff;
        // height: 100vh;
        .layout-container {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 25px;
        }
        .header-container {
            z-index: 5;
            // position: unset;
        }
    }
    .user-menu-section {
        display: block !important;
        width: 100%;
        cursor: pointer;
        max-width: 45px;
        .dropdown-menu {
            top: 58px !important;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            margin: 0 auto !important;
            left: 0 !important;
            right: 0 !important;
            transform: none !important;
            .dropdown-item {
                padding: 0.75rem 1rem;
            }
        }
        .dropdown-toggle::after {
            display: none;
        }
    }

    .header-container {
        .logo {
            padding: 0 !important;
            width: 50%;
            img {
                width: 120px;
            }
            .header-container {
                &.row {
                    position: relative;
                    flex-wrap: wrap;
                }
                .help-support {
                    display: none;
                }
            }
        }
        .user-sign-option {
            ul {
                margin: 0;
                padding-top: 0 !important;
            }
            .dropdown-menu {
                top: 58px !important;
                width: unset;
                min-width: unset;
                max-width: unset;
                margin: 0px -45px !important;
                left: 0 !important;
                right: 0 !important;
                transform: none !important;
            }
        }
    }

    .header-container .logo .header-container > .header-container .user-sign-option {
        padding-right: 0;
        width: 40%;
        text-align: right;
    }
    .header-container > .row > div {
        flex: 1;
    }

    .candidate-holder {
        .layout-container {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 72px;
        }
        .candidate-header {
            width: 100%;
            padding: 5px 10px;
            h3 {
                //styleName: Body text bold;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0px;
                // text-align: left;
            }
        }
    }
}

@media (max-width: 400px) {
    .header-container {
        .user-sign-option {
            padding-left: 20px;
            padding-right: 0;
            ul {
                padding-left: 0 !important;
                padding-top: 2px !important;
                margin-left: -10px;
            }
            .user-menu-section {
                top: 53%;
            }
        }
    }
}
