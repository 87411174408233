.PageContext {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    // margin-top: -3%;
    // margin-top: 125px;

    .Header {
        margin-top: 15px;
        text-align: center;
    }

    .backarrow {
        width: 20px;
        height: 20px;
    }
    .PageHeading {
        margin-left: 38%;
        font-family: Poppins;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
    }

    .QueButton {
        width: 15%;
        height: 40px;
        margin-left: auto;
        border-radius: 1px;
        border: none;
        padding: 8px;
        gap: 8px;
        font-weight: 600;
        background: #009199;
        color: white;
    }

    .MyQuesSearch {
        width: 100%;
        height: 50px;
        // margin-top: 3%;
        outline: none;
        border: 1px;
        border-color: white;
        padding-left: 36px;
    }

    .MyQuesSearch::placeholder {
        text-align: left;
        color: #999;
        display: inline-block;
    }

    .table-admin {
        background: #fff;

        .TableHeader {
            background-color: #161616;
            color: #fff;
            height: 65px;
            font-weight: lighter;
            align-items: center;
            vertical-align: middle;
            justify-content: center;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
        }

        .KnowledgeArea {
            width: 35%;
            text-align: left;
        }

        th {
            text-align: left;
            height: 53px;
        }

        tr {
            .empty-list {
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                // padding: 20px;
                // margin-top: 8px;
                width: 100%;
            }

            td {
                vertical-align: middle;
                border-top: 15px solid #f2f3f3 !important;
                // padding: 13px 20px;

                p {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                }
            }
        }

        .table {
            margin-bottom: 0px;

            thead th {
                // text-align: center;
                padding: 13px 20px;
                color: #fff;
                background-color: #212425;
            }

            tbody {
                tr {
                    .empty-list {
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;

                        img {
                            width: 250px;
                            height: 160px;
                        }

                        p {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            margin-top: 15px;
                            color: #696873;
                        }
                    }
                    .viewevent {
                        cursor: pointer;
                        margin: 15px 0;
                    }

                    .editicon {
                        height: 32px;
                        width: 32px;
                    }

                    .edittext {
                        vertical-align: middle;
                        border-top: 13px solid #f2f3f3 !important;
                        padding: 13px 13px;

                        p {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0px;

                            .react-switch-bg {
                                width: 27px !important;
                                height: 12px !important;
                            }

                            .react-switch-handle {
                                width: 10px !important;
                                height: 10px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
