body {
  margin: 0;
  font-family: Poppins, sans-serif !important;
  background-color: #f2f3f3;
  overflow: auto !important;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }
  & ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 10px;
    background-color: #e9e8e8;
  }
}

@media (max-width: 767px) {
  body {
    overflow: unset !important;
  }
}
