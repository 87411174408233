.ball-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.ball-loader-ball {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
    animation: bounce 0.7s infinite ease-in-out;
}

.ball1 {
    animation-delay: 0.32s;
}

.ball2 {
    animation-delay: 0.16s;
}

@keyframes bounce {
    0%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(5px);
    }
}

.three-dots {
    .loader {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        position: relative;
        margin: 0 auto;
    }
    #loader-4 span {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #3498db;
        margin: 20px 5px;
        opacity: 0;
    }

    #loader-4 span:nth-child(1) {
        animation: opacitychange 1s ease-in-out infinite;
    }

    #loader-4 span:nth-child(2) {
        animation: opacitychange 1s ease-in-out 0.33s infinite;
    }

    #loader-4 span:nth-child(3) {
        animation: opacitychange 1s ease-in-out 0.66s infinite;
    }

    @keyframes opacitychange {
        0%,
        100% {
            opacity: 0;
        }

        60% {
            opacity: 1;
        }
    }
}
