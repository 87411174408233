@import "../../App.scss";

.test-library-holder {
  padding-top: 15px;
  .test-search-box {
    text-align: center;
    // height: 398px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 0px #0000001a;
    padding: 80px 70px;
    border-radius: 4px 4px 0px 0px;
    p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color: $body-text;
      padding-top: 13px;
    }
    .has-search {
      position: relative;
      width: 62%;
      box-shadow: 0px 4px 25px 0px #00000026;

      .form-control-feedback {
        position: absolute;
        z-index: 0;
        display: block;
        height: 2.375rem;
        line-height: 2.375rem;
        text-align: center;
        pointer-events: none;
        color: #aaa;
        top: 12px;
        width: 24px;
        left: 18px;
      }

      .form-control {
        padding-left: 3.3rem;
        height: 64px;
      }
    }

    .trending {
      color: $caption;
      //styleName: Caption;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      // display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .categories {
        cursor: pointer;
        border: 1.04px solid #d7dadb;
        border-radius: 4px;
        height: 83px;
        min-width: 93px;
        border-radius: 4px;
        padding: 10px 2px;
        color: $heading;
        //styleName: Caption 10;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0px;
        background: linear-gradient(0deg, #ffffff, #ffffff);
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  .test-count {
    padding: 30px;
    background: #ffffff;
    border-radius: 0px 0px 4px 4px;
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
    margin-top: 1px;
    aside {
      text-align: center;
      //styleName: Body Text;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $body-text;
    }
    .count-box {
      border: 1px solid #d7dadb;
      padding: 24px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .counts {
        font-family: Poppins;
        font-size: 48px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0px;
        text-align: left;
        color: $heading;
      }
      p {
        //styleName: Body Text;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        color: #878f92;
      }
    }
  }
}

.coding-test-container {
  background-color: #fff;
  height: 100vh;
  padding: 30px 100px;
  margin-top: -25px;
  margin-bottom: -25px;
  padding-bottom: 35px;
  .coding-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 58%;
    h3 {
      //styleName: Page Heading;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      color: #000000;
    }
    p {
      //styleName: Body Text;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $body-text;
    }
  }
  .count-header {
    margin-top: 20px;
    padding: 15px;
    background-color: $dark-background;
    display: flex;
    justify-content: space-around;
    align-items: center;
    h4 {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      text-align: center;
      color: $success;
    }
    span {
      font-family: Poppins;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      text-align: left;
      color: $success;
    }
  }
  .test-input {
    margin-top: 23px;
    label {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: $heading;
    }
    input {
      // width: 30%;
      // border-radius: 0px;
    }
    .checkbox-container {
      display: flex;
      flex-wrap: wrap;
    }
    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      background-color: $button-color !important;
    }
    .checkbox-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 40px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: $body-text;
      width: 190px;
    }
  }
  .load-btn {
    background-color: $button-color;
    color: #fff;
    min-width: 160px;
    height: 38px;
    border: none;
  }
  .coding-question-list {
    margin-top: 30px;
    .list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #000000;
      }
      p {
        //styleName: Body Text;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: $body-text;
      }
      .outline-btn {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        border: 1px solid $button-color;
        color: $button-color;
        background-color: transparent;
        height: 40px;
        padding: 0px 10px;
      }
    }
    .lists {
      margin-top: 20px;
      .question-list {
        border: 1px solid #b3b3b3;
        margin-top: 5px;
        box-shadow: 0px 1px 1px 0px #0000001a;
        background-color: #fff;
        height: 64px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        padding: 20px;
        div {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: $body-text;
          &:nth-child(1) {
            width: 5%;
          }
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 20%;
            text-align: center;
          }
          &:nth-child(3) {
            width: 20%;
            text-align: center;
          }
          &:nth-child(4) {
            width: 20%;
            text-align: center;
          }
          &:nth-child(5) {
            width: 20%;
            text-align: center;
          }
          &:nth-child(6) {
            width: 20%;
            text-align: center;
          }
        }
      }
      .empty-list {
        background-color: #fff;
        margin-top: 5px;
        box-shadow: 0px 1px 1px 0px #0000001a;
        border-radius: 4px;
        padding: 25px 18px;
        text-align: center;
        padding-bottom: 50px;
        p {
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: center;
          color: $success;
        }
      }
    }
    .save-btn {
      border: none;
      background-color: $button-color;
      color: #fff;
      width: 240px;
      height: 40px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
    }
  }
}

@media (max-width: 767px) {
  .test-count {
    .count-box {
      margin-top: 20px;
      width: 100%;
    }
  }
  .coding-test-container {
    height: unset;
    padding: 30px 0px;
    margin-top: 50px;
    .coding-header {
      width: 75%;
    }
    .coding-question-list {
      .list-header {
        flex-wrap: wrap;
        .outline-btn {
          margin-top: 10px;
          font-size: 12px;
          padding: 0px 4px;
        }
      }
      .lists {
        .question-list {
          padding: 10px;
          justify-content: space-between;
          height: unset;
          min-height: 65px;
          div {
            &:nth-child(1) {
              width: 8%;
            }
            &:nth-child(4) {
              width: 7%;
            }
            &:nth-child(5) {
              width: 5%;
            }
          }
        }
      }
    }
    .count-header {
      h4 {
        font-size: 12px;
      }
      span {
        font-size: 27px;
      }
    }
  }
}

.select-drawer {
  z-index: 5 !important;
}

// .css-oqbj7d {
//   z-index: 5 !important;
// }

.edit-modal {
  width: 360px;
  h2 {
    //styleName: Section Heading;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: $heading;
  }
  .sticky-holder {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
  }
  p {
    margin-top: 15px;
    //styleName: Body Text;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $heading;
  }
  label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: $heading;
  }
  .items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    margin-left: 2px;
    input {
      width: 116px;
      height: 48px;
      border-radius: 4px;
      border: none;
      border: 1px solid #d7dadb;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      border-radius: 4px;
      box-shadow: 0px 0px 4px 0px #00000026;
      margin-right: 10px;
      //styleName: Body Text;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $body-text;
    }
    .active {
      border: 1px solid $success;
    }
  }
  .save-btn {
    margin-top: 15px;
    button {
      border: none;
      background-color: $button-color;
      color: #fff;
      width: 160px;
      height: 40px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
    }
  }
  .notified-text {
    display: flex;
    align-items: center;
    margin-top: 30px;
    p {
      margin-top: 0px;
      //styleName: Caption;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: $body-text;
    }
  }
}

@media (max-width: 767px) {
  .test-library-holder {
    padding-top: 0px;
    margin-top: 40px;
    background-color: #ffffff;
    .test-search-box {
      padding: 70px 25px;
      .has-search {
        width: 100%;
      }
    }
    .test-count {
      padding: 20px;
      p {
        line-height: 18px;
      }
      .counts {
        img {
          width: 25px;
        }
        font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }
}
