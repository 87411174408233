@import "../../App.scss";

.users-holder {
    .users-header {
        background-color: #fff;
        padding: 20px;
        text-align: center;

        h2 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 0px;
            text-align: center;
            color: $heading;
        }

        .user-btns {
            button {
                border-radius: 20px;

                &:nth-child(2) {
                    border-radius: 20px;
                }
            }
        }

        .add-btn {
            background-color: $button-color;
            color: #fff;
            border-radius: 0px !important;
            border: none;
            padding: 2px 25px;
            min-width: 240px;
            height: 48px;
            margin-top: 20px;
        }

        .active {
            color: $button-color;
            border: 1px solid $button-color;
        }

        button {
            background-color: #fff;
            padding: 2px 30px;
            border-radius: 20px 0px 0px 20px;
            border: 1px solid #d7dadb;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 700;
            line-height: 34px;
            letter-spacing: 0px;
            text-align: center;
            color: $heading;

            &:nth-child(2) {
                border-radius: 0px 20px 20px 0px;
            }
        }

        .has-search {
            margin-top: 20px;
            position: relative;
            width: 76%;

            .form-control-feedback {
                position: absolute;
                z-index: 0;
                display: block;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
                top: 5px;
                width: 24px;
                left: 8px;
            }

            .form-control {
                padding-left: 2.3rem;
                height: 48px;
            }

            input {
                border: 1px solid #d7dadb;
                border-radius: 0px;

                &::placeholder {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
            }
        }
    }

    .table-admin {
        background: #fff;

        .table {
            margin-bottom: 0px;

            thead th {
                // text-align: center;
                padding: 13px 20px;
                color: #fff;
                background-color: #212425;
            }

            tbody {
                tr {
                    .empty-list {
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding: 40px;
                        margin-top: 8px;
                        width: 100%;

                        // position: absolute;
                        img {
                            width: 350px;
                            height: 210px;
                        }

                        p {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            margin-top: 15px;
                            color: #696873;
                        }
                    }

                    td {
                        vertical-align: middle;
                        border-top: 15px solid #f2f3f3 !important;
                        // text-align: center;
                        padding: 13px 20px;

                        p {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0px;
                            color: $body-text;

                            .react-switch-bg {
                                width: 27px !important;
                                height: 12px !important;
                            }

                            .react-switch-handle {
                                width: 10px !important;
                                height: 10px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    // Define keyframes for loader animation
    @keyframes gp-loader-animation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    // Define styles for loader container
    .gp-loader-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    // Define styles for loader
    .gp-loader {
        border: 6px solid #f3f3f3;
        /* Light grey */
        border-top: 6px solid #009199;
        /* Blue */
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: gp-loader-animation 1s linear infinite;
    }
    .pointer {
        cursor: pointer;
    }
}

.add-user-modal {
    width: 29%;
    .modal-content {
        border-radius: 4px;
        border: none;
    }
    .modal-header {
        position: relative;
        padding: 0.7rem 0.7rem;
        text-align: center;
        display: block;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        h5 {
            color: #fff;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 22px;
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                margin-top: 5px;
            }
        }
        .sticky-close {
            position: absolute;
            right: 13px;
            top: 8px;
            cursor: pointer;
        }
    }
    .modal-body {
        label {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
            aside {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
        input {
            border-color: $chart-color;
        }
        textarea {
            border-color: $chart-color;
        }

        small {
            color: red;
            font-size: 12px;
        }
        .save-btn {
            margin-top: 20px;
            button {
                width: 90px;
                background-color: $button-color;
                border: none;
                border-radius: 4px;
                color: #fff;
                height: 40px;
                &:nth-child(2) {
                    margin-left: 10px;
                }
            }
        }
    }
}

.detail-modal {
    .modal-content {
        border-radius: 4px;
        border: none;
    }
    .modal-header {
        position: relative;
        padding: 0.7rem 0.7rem;
        text-align: center;
        display: block;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        h5 {
            color: #fff;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 22px;
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                margin-top: 5px;
            }
        }
        .sticky-close {
            position: absolute;
            right: 13px;
            top: 8px;
            cursor: pointer;
        }
    }
    .modal-body {
        label {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
            aside {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
    }
}

.custom-btns {
    background-color: #029199;
    color: #fff;
    border-radius: 8px;
    border-color: #029199;
    font-weight: 600;
    padding: 4px 16px;
}

@media (max-width: 767px) {
    .users-holder {
        margin-top: 70px;
        .users-header {
            .has-search {
                width: 100%;
            }
        }
    }

    .add-user-modal {
        width: unset;
    }
}
