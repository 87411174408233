@import "../../App.scss";
.gappeo-my-tests {
    .report-btns {
        margin-top: 20px;

        button {
            background-color: #fff;
            border: 1px solid $caption;
            box-sizing: border-box;
            border-radius: 20px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: $caption;
            width: 165px;
            height: 35px;
        }
        .active {
            color: #fff !important;
            background-color: $button-color;
            border: none;
        }
    }
    .search-test {
        .has-search {
            position: relative;
            width: 100%;

            .form-control-feedback {
                position: absolute;
                z-index: 0;
                display: block;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
                top: 5px;
                width: 24px;
                left: 8px;
            }

            .form-control {
                padding-left: 2.3rem;
                height: 48px;
            }
            input {
                border: 1px solid #d7dadb;
                border-radius: 0px;
                &::placeholder {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $caption;
                }
            }
        }
        select {
            height: 48px;
        }
        button {
            background-color: $button-color;
            color: #fff;
            border-radius: 0px;
            height: 48px;
            border: none;
            font-size: 14px;
            padding: 10px 20px;
        }
    }
    .table-admin {
        background: #fff;
        .empty-list {
            margin-top: 40px;
            height: 300px;
            text-align: center;
            padding: 20px;
        }
        .table {
            margin-bottom: 0px;
            thead th {
                text-align: center;
                padding: 13px;
                color: #fff;
                font-size: 14px;
                background-color: #212425;
            }
            tbody {
                tr {
                    .empty-list {
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding: 40px;
                        margin-top: 8px;
                        width: 100%;
                        // position: absolute;
                        img {
                            width: 350px;
                            height: 210px;
                        }
                        p {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            margin-top: 15px;
                            color: #696873;
                        }
                    }
                    td {
                        vertical-align: middle;
                        border-top: 15px solid #f2f3f3 !important;
                        text-align: center;
                        padding: 12px;
                        font-size: 14px;
                        h4 {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: #3e67b7;
                        }
                        h6 {
                            //styleName: Caption;
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $body-text;
                        }
                        p {
                            //styleName: Caption;
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $body-text;
                        }
                        h3 {
                            //styleName: Section Heading;
                            font-family: Poppins;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $body-text;
                        }
                        h5 {
                            //styleName: Caption;
                            font-family: Poppins;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $body-text;
                        }
                    }
                    .action {
                        cursor: pointer;

                        img {
                            display: inline-block;
                            vertical-align: middle;
                        }
                        button {
                            border: none;
                            background: none;
                        }
                    }
                }
            }
        }
    }
}

.test-report-holder {
    .test-report-header {
        box-shadow: 0px 1px 1px 0px #0000001a;
        background-color: $dark-background;
        padding: 20px;
        h3 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: 0px;
            text-align: left;
            color: #fff;
        }
        h6 {
            //styleName: Caption 10;
            font-family: Poppins;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0px;
            text-align: left;
            color: #fff;
        }
        p {
            //styleName: Body text narrow;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: #fff;
        }
        h4 {
            //styleName: Section Heading;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: #fff;
        }
    }
    .test-score {
        background-color: #fff;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        h2 {
            font-family: Poppins;
            font-size: 32px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: center;
            color: $success;
        }
        p {
            margin-top: 5px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: center;
            color: #000000;
        }
    }
    .individual-report {
        margin-top: 15px;
        box-shadow: 0px 1px 1px 0px #0000001a;
        background-color: #fff;
        padding: 20px;
        h4 {
            //styleName: Section Heading;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
        }
        p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: $caption;
        }
        .test-tabs {
            display: flex;
            // border-bottom: 1px solid #d7dadb;
            padding-left: 10px;
            padding-top: 20px;
            .active {
                background-color: white !important;
                border-bottom: none !important;
                h5 {
                    color: $button-color !important;
                    text-align: center;
                }
            }
            .test-tab {
                padding: 10px 0px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                min-height: 150px;
                border-radius: 24px 24px 0px 0px;
                border: 1px solid #d7dadb;
                // border-width: 1px, 1px, 0px, 1px;
                // border-style: solid;
                // border-color: #d7dadb;
                width: 15%;
                margin-right: 3px;
                background: #d7dadb;
                position: relative;
                .progress-circular {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50px);
                }
                h5 {
                    font-family: Poppins;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: $body-text;
                }
            }
        }
        .test-topic {
            .bg-succ {
                background-color: $success;
            }
            .progress {
                border-radius: 0px;
                background-color: #d9d9d9;
                height: 0.7rem;
            }
            tbody {
                tr {
                    td {
                        border-bottom: none;
                        p {
                            //styleName: Caption 10;
                            font-family: Poppins;
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 14px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $body-text;
                        }
                    }
                }
            }
            .accordion {
                .accordion-collapse {
                    z-index: 1;

                    .scoretext{
                        padding-top: 30px;

                        .testresult{
                            padding: 10px 0;
                            

                            .testinput{
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .card-header {
                    padding: 0.8rem 1rem;
                    color: $heading;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0px;
                    background-color: #fff;
                }
                .card-body {
                    select {
                        background-image: url("../../assets/select-indicator.svg");
                        background-repeat: no-repeat;
                        background-size: 15px;
                        background-position: 93%;
                    }
                    .ques-options {
                        list-style: none;
                        width: 55%;
                        li {
                            margin-bottom: 10px;
                            width: 100%;
                            border: 1px solid #d7dadb;
                            box-shadow: 0px 0px 4px 0px #00000026;
                            padding: 8px;
                            user-select: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 2px;
                            font-size: 14px;
                        }
                        .correct {
                            background-color: $success;
                            color: #fff !important;
                            border: 1px solid $success !important;
                            p {
                                color: #fff !important;
                                span {
                                    color: #fff !important;
                                }
                            }
                        }
                        .incorrect {
                            background-color: #fc3a3a;
                            color: #fff !important;
                            p {
                                color: #fff !important;
                                span {
                                    color: #fff !important;
                                }
                            }
                        }
                    }
                    .ques-describe {
                        background: #f7f8fa;
                        padding: 10px;
                    }
                    .submit-btn {
                        background-color: $button-color;
                        color: #fff;
                        border: none;
                        width: 98px;
                        height: 40px;
                    }
                    .div-row1 {
                        margin-top: 10px;
                        display: flex;
                        // justify-content: space-between;
                        align-items: center;
                        .circle {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            color: blue;
                            border: 1px solid blue;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        h6 {
                            font-size: 12px;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
    .image-lists {
        margin-top: 15px;
        box-shadow: 0px 1px 1px 0px #0000001a;
        background-color: #fff;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        h4 {
            //styleName: Section Heading;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
            width: 100%;
        }
        .image-list {
            margin-top: 10px;
            margin-right: 10px;
            position: relative;
            .time {
                position: absolute;
                bottom: 0px;
                left: 15px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: center;
                color: #fff;
            }
            img {
                width: 80px;
                height: 80px;
            }
        }
    }
    .visible-phone {
        display: none;
    }
}

@media (max-width: 767px) {
    .gappeo-my-tests {
        margin-top: 75px;
        padding-top: 20px;
        .report-btns {
            padding-left: 12px;
        }
        .search-test {
            padding: 10px;
            button {
                margin-top: 10px;
            }
        }
        table tr th:first-child {
            z-index: 5 !important;
        }
        .table-admin {
            width: 100%;
            min-height: 345px;
            overflow: scroll;
        }
        table th,
        table td {
            white-space: nowrap;
            padding: 10px 20px;
        }
        table tr th:first-child,
        table td:first-child {
            position: sticky;
            width: 100px;
            min-width: 130px;
            max-width: 130px;
            left: -1px;
            z-index: 3;
            background: #fff;
            overflow: hidden;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
        }

        table tr th {
            position: sticky;
            top: 0;
            z-index: 4;
            background: #fff;
        }
    }
    .test-report-holder {
        margin-top: 72px;
        .individual-report {
            .test-tabs {
                .test-tab {
                    width: 100%;
                }
            }
        }
        .visible-phone {
            display: block;
        }
        .hidden-phone {
            display: none;
        }
        .report-details .col-12 {
            display: flex;
            flex-wrap: wrap;
        }

        .report-details .col-12 .col-3 {
            width: 30%;
        }

        .report-details .col-12 .col-9 {
            width: 67%;
            padding-left: 20px;
        }

        .report-details .col-12 .col-9 label.label {
            word-break: break-word;
        }

        label.labletext2 {
            padding-left: 0 !important;
        }
    }
}
