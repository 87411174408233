@import "../../App.scss";

.gappeo-my-tests {
    .newtests {
        padding: 2.5rem 0;
        .caption {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: $heading;
        }
        .textclass {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .my-tests {
            background-color: #fff;
            padding: 15px;
            display: flex;
            // justify-content: space-between;
            // align-items: center;

            .has-search {
                position: relative;
                width: 100%;

                .form-control-feedback {
                    position: absolute;
                    z-index: 0;
                    display: block;
                    height: 2.375rem;
                    line-height: 2.375rem;
                    text-align: center;
                    pointer-events: none;
                    color: #aaa;
                    top: 2px;
                    width: 24px;
                    left: 8px;
                }

                .form-control {
                    padding-left: 2.3rem;
                    height: 40px;
                }
                input {
                    // border: none;
                    border-right: 1px solid #d7dadb;
                    border-radius: 0px;
                    &::placeholder {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: $caption;
                    }
                }
            }
            select {
                margin-left: 10px;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #000000 !important;
                width: 95%;
                padding-left: 0.5rem;
                // border-left: 1px solid #d7dadb !important;
                border-radius: 0px;
                border: 1px solid #d7dadb;
                height: 40px;
                box-shadow: none !important;
                background-color: #fff;
                cursor: pointer;
                color: $body-text;
                &:focus {
                    outline: none !important;
                }
            }
        }
        .create {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 27px;
                height: 27px;
            }
        }
        .mytestbtn {
            border-radius: 1px;
            border: none;
            // padding: 8px 15px;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            font-weight: 600;
            background: #009199;
            color: white;
            width: 150px;
            height: 40px;
        }
        .backarrow {
            position: absolute;
            width: 24px;
            height: 30px;
            gap: 0px;
            left: 0px;
            cursor: pointer;
        }
        .testscart {
            width: 50%;
            // height: 290px;
            border: 1px solid transparent;
            margin: 30px;
            text-align: center;
            padding: 27px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            h3 {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 700;
                line-height: 18px;
                text-align: center;
                color: #000000;
            }

            .BodyText {
                height: 45px;
                margin: 10px 0px;
                //styleName: Body Text;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: $body-text;
            }
        }

        .summarycard {
            margin: 10px 0;
            background: #ffffff;
            border: 1px 0px 0px 0px;
            // min-height: 500px;
            .summaryheader {
                width: 100%;
                height: 88px;
                gap: 20px;
                background: #212425;
                color: #fff;
            }

            .headeritem {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 100px;
                p {
                    //styleName: Caption;
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                    color: #fff;
                }
                h4 {
                    //styleName: Section Heading;
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 18px;
                    text-align: left;
                    color: #fff;
                }
            }

            .headtext {
                height: 16px;
            }

            #scroll {
                padding-right: 10px;
                & ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: white;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }
            }
            .summarybody {
                width: 100%;
                padding: 20px 35px;
                gap: 25px;
                .summaryfiels {
                    gap: 5px;
                    margin: 10px 0;
                }
                .css-16xfy0z-control {
                    .css-1hb7zxy-IndicatorsContainer {
                        .css-894a34-indicatorSeparator {
                            display: none;
                        }
                    }
                }
                .css-16xfy0z-control {
                    border-radius: 0px;
                }
                .css-13cymwt-control {
                    border-radius: 0px;
                }
                .css-1jqq78o-placeholder {
                    font-size: 15px;
                }
                .css-1fdsijx-ValueContainer {
                    height: 48px;
                }
                .summarydropdown {
                    width: 100%;
                    height: 48px;
                    padding: 0 11px;
                    // border: 1px solid #ccc;
                    box-sizing: border-box;
                    background: url("../../../public/images/mdi_menu-down.svg") no-repeat right 10px center;
                    background-size: 24px 24px;
                    &:focus {
                        outline: none;
                    }
                }
            }

            h5 {
                //styleName: Action Text;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
                color: $heading;
                cursor: pointer;
            }

            .testupdate {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d7dadb;
                padding: 20px;
                background: #fcfdfd;

                .testsummary {
                    width: 45%;
                    h3 {
                        //styleName: Body text bold;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: left;
                        color: $body-text;
                    }
                    p {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: $body-text;
                    }
                }

                .testlevel {
                    width: 25%;

                    display: flex;
                    // padding: 0px 8%;
                    align-items: center;
                    p {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: $body-text;
                    }
                }

                .testitems {
                    width: 10%;
                    gap: 25px;
                    display: flex;
                    justify-content: flex-end;
                    align-self: center;
                    .testicons {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
        .generate-btn {
            width: 200px;
            height: 40px;
            background: $button-color;
            color: #fff;
            border: none;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
        }
        .outline-btn {
            min-width: 200px;
            background-color: transparent;
            border: 1px solid $button-color;
            color: $button-color;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
        }
        .headclass {
            padding: 20px;
            .manuallback {
                cursor: pointer;
                margin-right: 30px;
                // position: absolute;
                position: static;
            }

            .manullhead {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                .manullheadmain {
                    height: 24px;
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-family: Poppins;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 32px;
                }

                .manullheadpara {
                    height: 24px;
                    gap: 0px;
                    width: 15%;
                    margin-left: auto;
                    //styleName: Action Text;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: center;
                    color: #878f92;
                }
            }

            .manuallsearch {
                padding: 0;
                gap: 10px;
                flex-direction: row;
                .has-search {
                    width: 70%;
                    // height: 50px;
                    // gap: 8px;
                    border: 1px solid #d7dadb;
                    background: #fcfdfd;
                    .form-control-feedback {
                        top: 4px;
                    }
                    input {
                        height: 48px;
                    }
                }
                .css-1jqq78o-placeholder {
                    font-size: 12px;
                }
                .css-1fdsijx-ValueContainer {
                    height: 48px;
                }
            }
        }
        .manuallbtn {
            width: 120px;
            height: 40px;
            margin-left: auto;
            background-color: $button-color;
            color: #fff;
            border: none;
        }

        .mannual-question-list {
            width: 100%;
            height: auto;
            margin-top: 15px;
            padding: 15px;

            .ques-item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .ques-title {
                    width: 60%;
                    display: flex;
                    align-items: center;
                    .checkbox {
                        width: 15px;
                        height: 15px;
                        margin: 0 10px;
                    }
                    .manuallquestion {
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        color: #878f92;
                    }
                    p {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        text-align: left;
                        color: $body-text;
                    }
                }

                .manuallinfo {
                    height: 32px;
                    width: 32px;
                }
            }
        }

        .manuallquestionsbody {
            padding: 0px 0;
            margin-bottom: 15px;

            .manuallsummarybody {
                padding: 36px 65px;
                h3 {
                    //styleName: Section Heading;
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 18px;
                    text-align: left;
                    color: $heading;
                }
                h2 {
                    //styleName: Action Text;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: $button-color;
                }
            }

            .question-list {
                width: 100%;
                height: 404px;
                padding: 20px 0;
                .question-selected {
                    width: 100%;
                    height: auto;
                    padding: 12px 0px 12px 0px;
                    border-bottom: 1px solid #d7dadb;
                    align-items: center;
                    justify-content: center;
                    ul {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        li {
                            h4 {
                                //styleName: Body text bold;
                                font-family: Poppins;
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 24px;
                                text-align: left;
                                color: $body-text;
                            }
                            h5 {
                                font-family: Poppins;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 18px;
                                text-align: left;
                                color: $body-text;
                            }
                            &:nth-child(1) {
                                width: 30%;
                                height: 40px;
                                p {
                                    //styleName: Caption;
                                    font-family: Poppins;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 16px;
                                    text-align: left;
                                    color: #878f92;
                                }
                            }
                            &:nth-child(2) {
                                width: 60%;
                            }
                            &:nth-child(3) {
                                width: 10%;
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .empty-question {
                padding: 100px 36%;
                text-align: center;
            }
        }
        .testpagebtn {
            gap: 10px;
            display: flex;
            margin-bottom: 15px;

            .submitbtn {
                width: 200px;
                height: 40px;
                border: none;
                background-color: $button-color;
                color: #fff;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                text-align: center;
            }

            .assbtn {
                width: 200px;
                height: 40px;
                background-color: transparent;
                border: 1px solid #009199;
                color: #009199;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                text-align: center;
            }
        }
    }

    .test-options {
        width: 90%;
        margin-left: 60px;
        .backarrow {
            left: 35px;
        }
        .search-tests {
            width: 94%;
            margin-left: 32px;
        }
    }
}
