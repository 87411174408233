:root {
  --button-color: #009199;
  --dark-background: #212425;
  --darker-background: #166b9c;
  --chart-color: #009199;
  --success: #02c573;
  --heading: #3d4143;
  --body-text: #555c5e;
  --caption: #878f92;
}

$button-color: var(--button-color);
$dark-background: var(--dark-background);
$darker-background: var(--darker-background);
$chart-color: var(--chart-color);
$success: var(--success);
$heading: var(--heading);
$body-text: var(--body-text);
$caption: var(--caption);

#fc_frame,
#fc_frame.fc-widget-normal {
  z-index: 999999999 !important;
}
.custom_fc_frame {
  bottom: 15px;
  height: 75px;
  min-height: 73px;
  min-width: 65px;
  overflow: hidden;
  position: fixed;
  right: 15px;
  z-index: 2147483600;
}

ul {
  padding-left: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px;
}

.form-group {
  margin-bottom: 0.5rem;
}

.border-none {
  border: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

//main heading

h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: $heading;
}

//section heading
h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: $heading;
}

.caption-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: $caption;
}

.elipsis-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: $button-color;
}
.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.MuiSnackbar-anchorOriginBottomCenter {
  width: 30%;
  .MuiPaper-root {
    // background-color: $dark-background !important;
    font-weight: 600 !important;
    font-family: Poppins, sans-serif !important;
    justify-content: center;
    border-radius: 0px !important;
  }
  .MuiSnackbarContent-message {
    font-size: 15px;
    color: #fff;
  }
  .mdc-snackbar {
    z-index: 9999 !important;
    text-align: center;
  }
}

body {
  top: 0px !important;
}

/* Style the hide powered by text */
#google_translate_element {
  color: transparent;
}

#google_translate_element a {
  display: none;
}

div.goog-te-gadget {
  color: transparent !important;
}

.goog-gt-vt {
  display: none !important;
}
.goog-te-banner-frame {
  display: none !important;
}

.goog-tooltip,
.goog-text-highlight {
  display: none !important;
}
.goog-te-combo {
  color: #000000;
  font-size: 16px !important;
  font-weight: bold;
  background: #fff;
  border-color: #009199;
  font-family: "poppins" !important;
  outline: none;
  // background-image: url("./assets/select-indicator.svg");
  // background-repeat: no-repeat;
  // background-size: 15px;
  // background-position: 98%;
  border-radius: 4px;
  width: 180px;
  height: 42px;
  padding: 7px;
}

.goog-te-combo option {
  background: #fff;
  color: #000000;
  font-size: 20px;
}

/* Style the tooltip when hovering over the translation button */
.goog-tooltip {
  display: none !important;
}
.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none !important;
}

@media (max-width: 767px) {
  .MuiSnackbar-anchorOriginBottomCenter {
    width: 100%;
  }
}

.screen-loader {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    padding: 10px;
  }
  .css-uhb5lp {
    padding: 30px;
  }
}

//used in test library
.suggestion-container {
  box-shadow: 0px 4px 25px 0px #00000026;
  border: 1px solid #d7dadb;
  // height: 188px;
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 64px;
  // width: 56%;
  // max-width: 90%;
  // left:2px;
  background: #fff;
  box-shadow: 0px 5px 7px 2px #0000001f;
  text-align: left;
  // border-bottom-left-radius: 5px;
  border-radius: 4px;
  max-height: 300px;
  min-height: 45px;
  overflow: auto;
  & ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 10px;
    background-color: #e9e8e8;
  }
  ul {
    // padding: 10px 0px;
    &:hover li:hover {
      background: #f2f3f3;
    }
    li {
      display: block;
      cursor: pointer;
      width: 100%;
      padding: 10px 0 10px 20px;
      margin: 0;
      font-size: 14px;
      h3 {
        //styleName: Body Text;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: $body-text;
      }
      .skill-tags {
        width: 90px;
        padding: 4px;
        border-radius: 20px;
        background-color: #f2f3f3;
        .skill-tag {
          display: flex;
          justify-content: center;
          align-items: center;
          //styleName: Caption;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          color: $success;
        }
      }
    }
  }
}
.no-border {
  border: none !important;
}

.footer {
  p {
    //styleName: Body Text;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #000000;
  }
}

.test-details {
  // width: 936px;
  .modal-body {
    background-color: #f2f3f3;
    position: relative;
    padding: 50px;

    .sticky-close {
      position: absolute;
      right: 10px;
      top: 10px;
      img {
        cursor: pointer;
      }
    }
    .selected-test {
      width: 42%;
      margin-left: 7px;
      box-shadow: 0px 1px 1px 0px #0000001a;
      min-height: 50px;
      border: 1px solid #d7dadb;
      border-radius: 4px;
      background-color: #fff;
      height: 500px;
      .selected-list-header {
        background: #212425;
        min-height: 88px;
        color: #fff;
        border-radius: 4px 4px 0px 0px;
        box-shadow: 0px 1px 1px 0px #0000001a;
        text-align: center;
        padding: 7px;
        h4 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          color: $success;
        }
        h5 {
          //styleName: Body text bold;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
          color: #fff;
          padding: 4px 0px;
        }
        p {
          //styleName: Caption 10;
          font-family: Poppins;
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0px;
          color: $caption;
          margin-bottom: 0px;
        }
      }
      .selected-list {
        padding: 15px;
        height: calc(64vh - 95px);
        overflow-y: auto;
        & ::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: white;
        }
        &::-webkit-scrollbar {
          width: 6px;
          background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          max-height: 10px;
          background-color: #e9e8e8;
        }
        h1 {
          //styleName: Body text bold;
          font-family: Poppins;
          font-size: 14px !important;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          color: $heading;
        }
        p {
          //styleName: Caption 10;
          font-family: Poppins;
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0px;
          text-align: left;
          color: $caption;
        }
        .selected-list-item {
          margin-top: 10px;
          display: flex;
          align-items: center;
          background: #f2f3f3;
          // height: 48px;
          border-radius: 2px;
          justify-content: space-between;
          padding: 7px 10px;
          h1 {
            //styleName: Body Text;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
          }
        }
        h5 {
          //styleName: Body text bold;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: left;
          color: $heading;
        }
        p {
          //styleName: Caption;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          color: $caption;
        }
      }
    }
    .selected-question {
      width: 54%;
      margin-left: 15px;
      padding: 10px;
      max-height: 305px;
      overflow-y: auto;
      box-shadow: 0px 1px 1px 0px #0000001a;
      background-color: #fff;
      h3 {
        //styleName: Section Heading;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: $heading;
      }
      p {
        //styleName: Body Text;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: $heading;
      }
      & ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: white;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        max-height: 10px;
        background-color: #e9e8e8;
      }
      .question {
        width: 100%;
        // height: 89px;
        border: 1px solid #d7dadb;
        border-radius: 4px;
        padding: 15px;
        margin-top: 10px;
        h4 {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: $body-text;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        span {
          //styleName: Caption;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          color: $body-text;
        }
      }
    }
  }
}

.confirm-modal {
  width: 550px;
  padding: 20px;

  .modal-header {
    position: relative;
    border-bottom: none;
    padding: 22px 15px;
    padding-bottom: 0px;

    h2 {
      font-size: 24px;
    }
  }
  .sticky-close {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
    img {
      width: 15px;
    }
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  span {
    color: red;
  }

  h1 {
    // text-align: left;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;

    a {
      text-decoration: underline !important;
    }
  }

  .save-btn {
    margin-top: 24px;
    font-family: Poppins;

    // text-align: right;
    button {
      border: none;
      background-color: $button-color;
      min-width: 78px;

      height: 40px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      border-radius: 4px;

      &:nth-child(2) {
        background-color: #fff;
        border: none;
        border: 1px solid #9d99bf;
        color: #222222;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

@media (max-width: 767px) {
  #fc_frame.fc-widget-normal {
    bottom: 95px !important;
    right: 6px !important;
  }
  .suggestion-container {
    width: 100%;
  }
  .test-details {
    // width: 936px;
    .modal-body {
      background-color: #f2f3f3;
      position: relative;
      padding: 20px;
      .sticky-close {
        position: absolute;
        right: 5px;
        top: 5px;
        img {
          cursor: pointer;
        }
      }
      .selected-test {
        width: 100%;
        margin-left: 7px;
        box-shadow: 0px 1px 1px 0px #0000001a;
        min-height: 50px;
        border: 1px solid #d7dadb;
        border-radius: 4px;
        background-color: #fff;
        height: 345px;
        .selected-list-header {
          background: #212425;
          min-height: 88px;
          color: #fff;
          border-radius: 4px 4px 0px 0px;
          box-shadow: 0px 1px 1px 0px #0000001a;
          text-align: center;
          padding: 7px;
          h4 {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            color: $success;
          }
          h5 {
            //styleName: Body text bold;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            color: #fff;
            padding: 4px 0px;
          }
          p {
            //styleName: Caption 10;
            font-family: Poppins;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0px;
            color: $caption;
            margin-bottom: 0px;
          }
        }
        .selected-list {
          padding: 15px;
          height: calc(64vh - 95px);
          overflow-y: auto;
          & ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: white;
          }
          &::-webkit-scrollbar {
            width: 6px;
            background-color: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            max-height: 10px;
            background-color: #e9e8e8;
          }
          h1 {
            //styleName: Body text bold;
            font-family: Poppins;
            font-size: 14px !important;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
          }
          p {
            //styleName: Caption 10;
            font-family: Poppins;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0px;
            text-align: left;
            color: $caption;
          }
          .selected-list-item {
            margin-top: 10px;
            display: flex;
            align-items: center;
            background: #f2f3f3;
            // height: 48px;
            border-radius: 2px;
            justify-content: space-between;
            padding: 7px 10px;
            h1 {
              //styleName: Body Text;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0px;
              text-align: left;
              color: $heading;
            }
          }
          h5 {
            //styleName: Body text bold;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: $heading;
          }
          p {
            //styleName: Caption;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            color: $caption;
          }
        }
      }
      .selected-question {
        width: 100%;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
  .confirm-modal {
    width: unset;
  }
}

.lightbox-wrapper {
  .sticky-close {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    top: 25px;
    cursor: pointer;
    z-index: 100000;
    img {
      width: 15px;
    }
  }
  p {
    margin: 1.5em 0;
    color: #aaa;
  }

  img {
    max-height: 50vh;
  }

  a {
    color: inherit;
  }

  a:hover {
    color: #bbb;
  }

  .small {
    font-size: 0.8em;
  }

  /** LIGHTBOX MARKUP **/

  .lightbox {
    /* Default to hidden */
    display: none;

    /* Overlay entire screen */
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // width: 100vw;
    /* A bit of padding around image */
    padding: 1em;

    /* Translucent background */
    background: rgba(0, 0, 0, 0.8);
  }

  /* Unhide the lightbox when it's the target */
  .lightbox:target {
    display: block;
  }

  .lightbox span {
    /* Full width and height */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    height: 100%;
    img {
      // width: 100%;
      height: 85vh;
    }
  }
}
